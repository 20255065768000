import React, { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { Col, Row, Space, List, Button, message, Image } from 'antd';
import globalConst from '../../constants/global_const';
import { UserContext } from './UserContext';
import { useCookies } from 'react-cookie';
import { getLocalStorageInfo, setLocalStorageInfo } from '../../utils/Common';
import { PhoneTwoTone, AudioMutedOutlined, AudioOutlined, StopOutlined, TrademarkOutlined } from '@ant-design/icons';
import PhoneKeyboard from './PhoneKeyboard';
import { Device } from '@twilio/voice-sdk';
import '../../utils/PhoneCall.css';


function TwilioClientSection (user2Child) {
    const [selectedClient, setSelectedClient] = useState(['Not yet select']);
    const [selectedStatus, setSelectedStatus] = useState(['Not yet select']);
    const [incomingPhoneNumber, setIncomingPhoneNumber] = useState('');
    const [incomingCall, setIncomingCall] = useState(null);
    const [call, setCall] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [device, setDevice] = useState(null);
    const [deviceStatus, setDeviceStatus] = useState('');
    const [callStatus, setCallStatus] = useState('Not set');
    const [callControlsVisible, setCallControlsVisible] = useState(false);
    const [volumeIndicatorsVisible, setVolumeIndicatorsVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isMuted, setIsMuted] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordingSid, setRecordingSid] = useState(null);

    const clientName = 'DYMVietNam';

    let userList = user2Child.user2Child;
    const divRightStyle = {
        paddingLeft: '0 !important',
    };

    const success = (contents) => {
        messageApi.open({
            type: 'success',
            content: contents,
            className: 'custom-class',
            style: { marginTop: '20vh' },
        });
    };


    // console.log('User list on client section ', userList);
    //return false;

    const statusItems = [
        { label: '不在', value: '0' },
        { label: '興味なし', value: '1' },
        { label: '即断り', value: '3' },
        { label: '着信拒否', value: '4' },
        { label: '再アクション', value: '5' },
        { label: '面談アサイン', value: '6' },
        { label: 'イベントアサイン', value: '7' },
        { label: '電話番号不備', value: '8' },
        // { label: '接触前', value: '9' },
        { label: '追客中', value: '10' },
        { label: 'サービス停止', value: '11' },
        { label: 'サービス停止（メールOK）', value: '12' },
        { label: '前日確認OK', value: '13' },
        { label: 'キャンセル', value: '14' },
        { label: '日程変更', value: '15' },
    
    ];
    
    const setStatus = (statusLabel, statusId) => {
        setLocalStorageInfo('selected-status', '');
        setLocalStorageInfo('selected-status', JSON.stringify(statusId));
        setSelectedStatus(statusLabel + '-' + statusId);
    }
    
    const setOtherClient = (userTitle, pmi, userId) => {
        //success('ID が user である他のユーザーを変更する ' + userId);
        setLocalStorageInfo('receipt-id', '');
        setLocalStorageInfo('phone-from', '');
        setLocalStorageInfo('phone-name', '');
        setLocalStorageInfo('receipt-id', JSON.stringify(userId));
        setLocalStorageInfo('phone-from', JSON.stringify(pmi));
        setLocalStorageInfo('phone-name', JSON.stringify(userTitle));
        setSelectedClient(userTitle + ' | ' + userId);
        // console.log('change user');
        initializeDevice();
    }

    const optClients = useMemo(() => {
        if (userList && Object.keys(userList).length > 0) {
          return userList.map((item) => ({
            title: item.friendlyName,
            id: item.sid,
            pmi: item.phoneNumber,
            
          }));
        }
    }, [userList]);

    const acceptIncomingCall = () => {
        if (incomingCall) {
            incomingCall.accept();
            setCall(incomingCall);
            log('Accepted incoming call.');
        }
    };

    const rejectIncomingCall = () => {
        if (incomingCall) {
            incomingCall.reject();
            log('Rejected incoming call.');
            resetIncomingCallUI();
        }
    };

    const hangupCall = () => {
        if (call) {
            log('Attempting to hang up call.');
            call.disconnect();  // Ngắt kết nối cuộc gọi
            log('Call disconnected.');
            resetIncomingCallUI();  // Đặt lại giao diện người dùng cuộc gọi đến
            setCall(null);  // Đặt lại trạng thái cuộc gọi
        } else {
            log('No call to hang up.');
        }
    };

    const resetIncomingCallUI = () => {
        setIncomingCall(null);
        setIncomingPhoneNumber('');
    };

    const log = (message) => {
        console.log(message);
        messageApi.open({
            type: 'info',
            content: message,
            className: 'custom-class',
            style: { marginTop: '20vh' },
        });
    };

    const initializeDevice = async () => {
        const selectedPhoneName = getLocalStorageInfo('phone-name');
        const selectedPhoneFrom = getLocalStorageInfo('phone-from');
        const selectedPhoneTo = getLocalStorageInfo('phone-to');
        const selectedReceipt = getLocalStorageInfo('receipt-id');
        const userInfo = getLocalStorageInfo('curUser');
        const twilioInfo = getLocalStorageInfo('twilio-info');
    
        let callParam = {
            "From": '+17078760766',
            "To": '+84966150638',
            "identity": clientName
        };
    
        if (selectedPhoneName && selectedPhoneName.length > 0) {
            callParam = {
                "From": selectedPhoneFrom,
                "To": selectedPhoneTo,
                "identity": selectedPhoneName
            }
        }
    
        //console.log('Twilio info on load ', twilioInfo, callParam);
    
        try {
            // Kiểm tra và hủy thiết bị hiện tại nếu có
            if (device) {
                device.disconnectAll();  // Ngắt kết nối tất cả các cuộc gọi hiện tại
                device.destroy();  // Gỡ bỏ thiết bị hiện tại
                log('Current device removed.');
            }
    
            const response = await axios.post(globalConst.TWILIO_SERVEICE_URL + '/start-session', callParam, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                },
            });
            //console.log('token return ', response);
    
            const { token } = response.data;
            const newDevice = new Device(token, {
                logLevel: 1,
                codecPreferences: ["opus", "pcmu"],
            });
    
            addDeviceListeners(newDevice);
    
            try {
                await newDevice.register();  // Đợi cho đến khi thiết bị được đăng ký
                setDevice(newDevice);
                log('Initializing device');
                setLocalStorageInfo('twilio-info', JSON.stringify(response.data));
            } catch (error) {
                console.log('Error registering device:', error);
                setDeviceStatus(`Error registering device: ${error.message}`);
            }
        } catch (error) {
            console.log('Error starting session:', error);
            success('Can not registry deveice with this user '+ selectedPhoneName);
            setLocalStorageInfo('phone-name', '');
            setLocalStorageInfo('phone-from', '');
            setDeviceStatus(`Error starting session: ${error.message}`);
    
            // Kiểm tra và xử lý lỗi từ Twilio
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.message === 'Invalid client name') {
                    log('Invalid client name. Please check the client name and try again.');
                } else {
                    log(`Twilio Error: ${error.response.data.message}`);
                }
            } else {
                log('An unexpected error occurred. Please try again.');
            }
        }
    };
    
    
    const addDeviceListeners = (device) => {
        device.on('registered', () => {
            log('Twilio.Device Ready to make and receive calls!');
            setCallControlsVisible(true);
        });

        device.on('error', (error) => {
            log(`Twilio.Device Error: ${error.message}`);
        });

        device.on('incoming', handleIncomingCall);

        device.audio.on('deviceChange', updateAllAudioDevices);

        if (device.audio.isOutputSelectionSupported) {
            log('Audio selection is supported.');
        }
    };

    const handleIncomingCall = (connection) => {
        setIncomingPhoneNumber(connection.parameters.From);
        setIncomingCall(connection);
        setCallStatus('Incoming call');
        log(`Incoming connection from ${connection.parameters.From}`);
    };

    const updateAllAudioDevices = () => {
        log('Audio device change detected.');
    };

    const toggleMute = () => {
        if (call) {
            call.mute(!isMuted);
            setIsMuted(!isMuted);
            log(isMuted ? 'Unmuted the call.' : 'Muted the call.');
        }
    };

    const startRecording = async () => {
        if (call && !isRecording) {
            try {
                const response = await axios.post(`${globalConst.TWILIO_SERVEICE_URL}/start-recording`, {
                    callSid: call.parameters.CallSid
                });
                setRecordingSid(response.data.sid);
                setIsRecording(true);
                log('Recording started.');
            } catch (error) {
                log(`Error starting recording: ${error.message}`);
            }
        }
    };

    const stopRecording = async () => {
        if (isRecording && recordingSid) {
            try {
                await axios.post(`${globalConst.TWILIO_SERVEICE_URL}/stop-recording`, {
                    recordingSid
                });
                setRecordingSid(null);
                setIsRecording(false);
                log('Recording stopped.');
            } catch (error) {
                log(`Error stopping recording: ${error.message}`);
            }
        }
    };


    useEffect(() => {
        //initializeDevice();
    }, []);

    // console.log('list user', optClients);
    return(
        <>
        {contextHolder}
        <div style={{ padding: '0', height: '420px', backgroundColor: '#fff' }}>
            <Row>
                <Col span={24}>
                    <div style={{ padding: '10px', height: '40px', width: '100%', backgroundColor: '#fff' }} >
                    <label style={{color: '#55f'}}><b>応対者 : </b></label> <label style={{color: '#52c41a'}}><b>{selectedClient}</b></label>
                    </div>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <div style={{ padding: '0', height: '400px', backgroundColor: '#fff', with: '98%' }}>
                        <div style={{padding: '10px', backgroundColor: '#55f', with: '98%'}}>
                            <div style={{display: 'table', margin: 'auto'}}><b><label style={{color: '#fff'}}>応対者</label></b></div>
                        </div>
                        <div
                        id="scrollableDiv"
                        style={{
                            height: 360,
                            overflow: 'auto',
                            padding: '0 0 0 10px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                            
                        }}
                        >
                        <List
                            itemLayout="horizontal"
                            dataSource={optClients}
                            renderItem={(item, index) => (
                            <List.Item onClick={() => setOtherClient(item.title, item.pmi, item.id)}>
                                <List.Item.Meta
                                    // avatar={<Avatar src={item.avatar} />}
                                    title={<a>{item.title}</a>}
                                    key={index}
                                />
                            </List.Item>
                            )}
                        />
                        </div>
                    </div>
                </Col>
                <Col span={12} style={{ divRightStyle }}>
                    <>

                    { 
                        incomingPhoneNumber ? 
                        <>
                        <div>
                            {incomingPhoneNumber && (
                                <>
                                <div style={{
                                    padding: "10px"
                                }}>
                                    <b>Incoming call from {incomingPhoneNumber}</b>
                                </div>
                                <Space>
                                    <Button type="primary" onClick={acceptIncomingCall}>
                                        Accept
                                    </Button>
                                    <Button type="dashed" danger onClick={rejectIncomingCall}>
                                        Reject
                                    </Button>
                                    <Button type="primary" ghost onClick={hangupCall}>
                                        Hang Up
                                    </Button>

                                </Space>
                                </>
                            )}
                        </div>

                        <div class="ring">
                            <div class="alo-phone alo-green alo-show" onClick={acceptIncomingCall}>
                                <div class="alo-ph-circle"></div>
                                <div class="alo-ph-circle-fill"></div>
                                <div class="alo-ph-img-circle"></div>
                            </div>
                        </div>

                        <Row>
                            <Col xs={8}>
                                <a href='#' onClick={toggleMute}>
                                    {
                                        isMuted ? 
                                        <AudioMutedOutlined style={{
                                            fontSize: '25px',
                                            color: 'hotpink',
                                        }} /> : 
                                        <AudioOutlined style={{
                                            fontSize: '25px',
                                            color: 'hotpink',
                                        }}/>
                                    }
                                </a>
                            </Col>

                            <Col xs={8}>
                                <a type="default" onClick={isRecording ? stopRecording : startRecording}>
                                    {
                                        isRecording ? 
                                        <StopOutlined style={{ fontSize: '25px', color: 'hotpink', }}/> : 
                                        <TrademarkOutlined style={{ fontSize: '25px', color: 'hotpink', }}/>
                                    }
                                </a>
                            </Col>

                            <Col xs={8}>
                                <a href='#' onClick={hangupCall}>
                                <StopOutlined style={{
                                    fontSize: '25px',
                                    color: 'hotpink',
                                }} />

                                </a>
                            </Col>


                        </Row>
                        </>
                        :
                        <div style={{ padding: '0', height: '440px', backgroundColor: '#fff', with: '98%'}}>
                            <PhoneKeyboard />
                        </div>

                    }
                    </>


                </Col>
            </Row>
        </div>

        </>
    );

}

export default TwilioClientSection;