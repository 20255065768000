import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { message, Table, Layout, Select, Space, Col, Row, Button, Modal, Form, Input, DatePicker, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useCookies } from 'react-cookie';
import { Loading, getToken, getFullDate, getFullDateTime } from '../../utils/Common';
import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import globalConst from '../../constants/global_const';

const { Content } = Layout;
const { Option } = Select;
const columnsInput = [
    { title: '候補者名', dataIndex: 'name', key: 'name', render: text => <a>{text}</a>, width: 150 },
    { title: 'Furigana', dataIndex: 'furigana', key: 'furigana', width: 150 },
    { title: '誕生日', dataIndex: 'birthday', key: 'birthday', width: 120 },
    { title: '性別', dataIndex: 'gender', key: 'gender', width: 120,
        render: (gender) => {
              return (
                <>{gender}</>
              );
        },  
    },
    { title: 'Eメール', dataIndex: 'email', key: 'email', width: 200 },
    { title: '番号', dataIndex: 'postal_code', key: 'postal_code', width: 120 },
    { title: '電話', dataIndex: 'phone', key: 'phone', width: 120 },
    { title: '住所', dataIndex: 'address', key: 'address', width: 250 },
    { title: '学校の種類', dataIndex: 'school_type', key: 'school_type', width: 120 },
    { title: '大学名', dataIndex: 'university_name', key: 'university_name', width: 150 },
    { title: '教育ストリーム', dataIndex: 'educational_stream', key: 'educational_stream', width: 150 },
    { title: '部門', dataIndex: 'department', key: 'department', width: 150 },
    { title: '卒業年', dataIndex: 'graduation_year', key: 'graduation_year', width: 120 },
    { title: '勤務年数', dataIndex: 'work_year', key: 'work_year', width: 120 },
    { title: '希望業界', dataIndex: 'desired_industry', key: 'desired_industry', width: 150 },
    { title: '合意した', dataIndex: 'agreed_at', key: 'agreed_at', width: 120 },
    // { title: '状態', dataIndex: 'status', key: 'status', width: 100 },
];


const CandidateList = () => {
    const [cookies] = useCookies(['call_token']);
    const curToken = cookies.call_token ? cookies.call_token : getToken();
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [isWaitting, setWaitting] = useState(false);
    const [projectData, setProjectData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [isEditUser, setIsEditUser] = useState(false);
    const [editingUser, setEditingUser] =  useState([]);
    const [projectID, setProjectID] = useState([]);

    const [wgData, setWgData] = useState([]);
    const [form] = Form.useForm();

    const columns = [
        // { title: 'ID', dataIndex: 'id', key: 'id', width: 80 },
        { title: '候補者名', dataIndex: 'name', key: 'name', render: text => <a>{text}</a>, width: 150 },
        { title: 'Furigana', dataIndex: 'furigana', key: 'furigana', width: 150 },
        { title: '誕生日', dataIndex: 'birthday', key: 'birthday', width: 120 },
        { title: '削除', dataIndex: 'gender', key: 'gender', width: 120,
            render: (gender) => {
                  return (
                    <>{gender}</>
                  );
            },  
        },
        { title: 'Eメール', dataIndex: 'email', key: 'email', width: 200 },
        { title: '番号', dataIndex: 'postal_code', key: 'postal_code', width: 120 },
        { title: '電話', dataIndex: 'phone', key: 'phone', width: 120 },
        { title: '住所', dataIndex: 'address', key: 'address', width: 250 },
        { title: '学校の種類', dataIndex: 'school_type', key: 'school_type', width: 120 },
        { title: '大学名', dataIndex: 'university_name', key: 'university_name', width: 150 },
        { title: '教育ストリーム', dataIndex: 'educational_stream', key: 'educational_stream', width: 150 },
        { title: '部門', dataIndex: 'department', key: 'department', width: 150 },
        { title: '卒業年', dataIndex: 'graduation_year', key: 'graduation_year', width: 120 },
        { title: '勤務年数', dataIndex: 'work_year', key: 'work_year', width: 120 },
        { title: '希望業界', dataIndex: 'desired_industry', key: 'desired_industry', width: 150 },
        { title: '合意した', dataIndex: 'agreed_at', key: 'agreed_at', width: 120 },
        { title: '状態', dataIndex: 'status', key: 'status', width: 100 },
        {
            title: 'アクション',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record)} >編集</Button>
                    <Button type="link" onClick={() => confirmDeleteCandidate(record)} loading={isWaitting}>削除</Button>
                </Space>
            ),
        },
    ];
        

    const showModal = (user = null) => {
        setModalTitle(user ? '候補者情報を更新する / Edit' : '新しい候補者を追加する');
        
        form.resetFields();
        form.setFieldsValue({'projectId': projectID});
        if (user) {    
            form.setFieldsValue({
                id: user.id,
				name: user.name,
                furigana: user.furigana,
                birthday: dayjs(user.birthday),
                gender: user.gender,
                email: user.email,
                postal_code: user.postal_code,
                phone: user.phone,
                address: user.address,
                school_type: user.school_type,
                university_name: user.university_name,
                educational_stream: user.educational_stream,
                department: user.department,
                graduation_year: dayjs(user.graduation_year),
                work_year: dayjs(user.work_year),
                desired_industry: user.desired_industry,
                agreed_at: dayjs(user.agreed_at),
                status: user.status,
            });

            setIsEditUser(true);
            form.setFieldsValue({ userId: user.id });
        } else {
            setIsEditUser(false);
            form.setFieldsValue({ userId: -1 });
        }
        setModalVisible(true);
    };

    const handleModalCancel = () => {
        form.resetFields();
        setModalVisible(false);
    };

    const handleFormSubmit = async (values) => {
        //console.log('data add new ', values);
        setWaitting(true);
        form.validateFields()
        .then(values => {
            const formattedValues = {
                ...values,
                birthday: values.birthday ? getFullDate(values.birthday.toISOString()) : null,
                agreed_at: values.agreed_at ? getFullDate(values.agreed_at.toISOString()) : null,
                graduation_year: values.graduation_year ? values.graduation_year.year().toString() : null,
                work_year: values.work_year ? values.work_year.year().toString() : null,
                phone: values.phone ? values.phone.toString() : null,
                postal_code: values.postal_code ? values.postal_code.toString() : null,
            };

            console.log('Form values:', formattedValues);
            //return false;
            if (values.userId === -1) {
                addCandidate(formattedValues);
                // console.log('data add new ', values);
            } else {
                console.log('data edit ', formattedValues);
                editCandidate(formattedValues);
            }
            form.resetFields();
            setModalVisible(false);
    
        })
        .catch(info => {
            console.log('Validate Failed:', info);
        });

        // fetchUserData();
    };


    useEffect(() => {
        loadProjectData()
    }, []);


    const optData = useMemo(() => {
        return wgData?.map(({ id, name }) => ({ value: id, label: name })) || [];
    }, [wgData]);

    const addCandidate = async (params) => {
        try {
            let pId = params.projectId;
            console.log('add new param ', params);
            const result = await axios.post(
                `${globalConst.REPORT_URL}/${pId}/candidates/store`,
                { ...params},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${curToken}`,
                    },
                },
            );
            
            messageApi.success(`メールで新規ユーザーを追加 `);
            setWaitting(false);
            handleChangeProject(pId);
        } catch (error) {
            messageApi.error('新しいユーザーを追加できませんでした');
            console.error('ユーザーの追加中にエラーが発生しました:', error);
            setWaitting(false);
        }
    };

    const editCandidate = async (params) => {
        
        let pId = projectID;
        let canId = params.userId;
        
        try {
            await axios.put(
                `${globalConst.REPORT_URL}/${pId}/candidates/${canId}`,
                params,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${curToken}`,
                    },
                },
            );
            messageApi.success('Candidate updated successfully');
            setWaitting(false);
            handleChangeProject(pId);
        } catch (error) {
            messageApi.error('Failed to update candidate');
            setWaitting(false);
            console.error('Error updating candidate:', error);
        }
        
    };


    const loadProjectData = useCallback(async() => {
        let proData = [];
        try {
            const { data } = await axios.get(`${globalConst.MANAGEMENT_URL}/projects`, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },

            });

            // console.log('Data return ', data.data);
            if(data.data) {
                data.data.forEach(element => {
                    proData.push({value: element.id, label: element.name}) 
                });
            }
            //console.log('Data project ', proData);
            setProjectData(proData);
            // handleChangeProject(projectData[0].value);
            setIsLoading(false);
        } catch (error) {
            setProjectData([]);
        } finally {
            messageApi.success('プロジェクトを選択して候補リストを表示してください');
            setIsLoading(false);
        }

    }, []);

    const handleChangeProject = async(value) => {
        // console.log('project select ', value);
        setProjectID(value);
        setWaitting(true);
        setUserData([]);
        try {
            const result = await axios.get(`${globalConst.REPORT_URL}/${value}/candidates`, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },
            });
            setUserData(result.data.data || []);
            setWaitting(false)
        } catch (error) {
            messageApi.error('データの取得に失敗しました。まずプロジェクトを選択する必要があります.');
        } finally {
            setWaitting(false);
        }
    }

    const confirmDeleteCandidate = (id) => {
        Modal.confirm({
            title: `Are you sure delete this candidte ?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => deleteCandidate(id),
        });
    };

    const deleteCandidate = async(record) => {
        console.log('Candiate edit ', record);
        let pId = record.pId;
        let canId = record.id;

        setWaitting(true);
       
        try {
            const { data } = await axios.delete(`${globalConst.REPORT_URL}/${pId}/candidates/${canId}`, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },
            });

            messageApi.success(`Candidate had deleted `);
            handleChangeProject(pId);

        } catch (error) {
            messageApi.error('Failed to delete Candidate');
        } finally {
            setWaitting(false);
        }
        
    }


    const listData = useMemo(() => {
        return userData.map(item => ({
            pId: projectID,
            key: item.id,
            id: item.id,
            name: item.name,
            furigana: item.furigana,
            birthday: item.birthday,
            gender: item.gender,
            email: item.email,
            postal_code: item.postal_code,
            phone: item.phone,
            address: item.address,
            school_type: item.school_type,
            university_name: item.university_name,
            educational_stream: item.educational_stream,
            department: item.department,
            graduation_year: item.graduation_year,
            work_year: item.work_year,
            desired_industry: item.desired_industry,
            agreed_at: item.agreed_at,
            status: item.status,
        }));
    }, [userData]);

    const getData = () => {
        return [];
    }

    if (isLoading) {
        return (
            <Layout>
                <HeaderLayout />
                <Layout>
                    <NavBarLayout />
                    <Content style={{ padding: '10px', height: '900px' }}>
                        <Loading />
                    </Content>
                    <FooterLayout />
                </Layout>
            </Layout>
        );
    }

    return (
        <Layout>
            <HeaderLayout />
            {contextHolder}
            <Layout>
                <NavBarLayout />
                <Content style={{ padding: '10px', height: '900px' }}>
                    <div style={{ minHeight: 760 }}>
                        <h1>候補者リスト </h1>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Row>
                                <Col span={24}>
                                    <label><b>プロジェクトを選択 </b> </label>
                                    <Select
                                        showSearch
                                        placeholder="プロジェクトを選択 "
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        allowClear
                                        options={projectData}
                                        onChange={handleChangeProject}
                                        loading={isWaitting}
                                    />
                                    &nbsp;&nbsp;
                                    <Button type="primary" onClick={() => showModal()}>新しい候補者を追加</Button>
                                </Col>
                            </Row>
                            
                        </Space>
                        <Table columns={columns} dataSource={listData} scroll={{ y: 590 }} loading={isWaitting} />

                        <Modal
                            title={modalTitle}
                            open={modalVisible}
                            onCancel={handleModalCancel}
                            footer={null}
                        >
                            <Form
                                id="inputForm"
                                layout="vertical"
                                onFinish={handleFormSubmit}
                                form={form}
                                initialValues={editingUser ? {
                                    ...editingUser,
                                    birthday: editingUser.birthday ? dayjs(editingUser.birthday): null,
                                    agreed_at: editingUser.agreed_at ? dayjs(editingUser.agreed_at) : null,
                                    graduation_year: editingUser.graduation_year ? dayjs(editingUser.graduation_year) : null,
                                    work_year: editingUser.work_year ? dayjs(editingUser.work_year) : null,
                                } : {}}
                            >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        {
                                            !isEditUser ?
                                            <Form.Item
                                                name='projectId'
                                                label='プロジェクトを選択'
                                                rules={[{ required: true }]}
                                            >
                                            <Select
                                                showSearch
                                                placeholder="プロジェクトを選択 "
                                                optionFilterProp="children"
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                allowClear
                                                options={projectData}
                                            />
                                            </Form.Item>
                                            : <></>
                                        }

                                        <Form.Item name="userId" hidden>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    {columnsInput.map((column, index) => (
                                    <Col span={8} key={column.key} style={{ marginBottom: '16px' }}>
                                        
                                        <Form.Item
                                            label={column.title}
                                            name={column.dataIndex}
                                            rules={
                                                column.dataIndex === 'name' ? [{ required: true }] 
                                                : column.dataIndex === 'email' ? [{
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail!',
                                                  },
                                                  { required: true, 
                                                    message: 'Please input your E-mail!', 
                                                }] 
                                                : []
                                            }
                                        >
                                        {column.dataIndex === 'gender' ? (
                                            <Select>
                                                <Option value="male">Male</Option>
                                                <Option value="female">Female</Option>
                                                <Option value="other">Other</Option>
                                            </Select>
                                        ) : column.dataIndex === 'birthday' || column.dataIndex === 'agreed_at' ? (
                                            <DatePicker style={{ width: '100%' }} />
                                        ) : column.dataIndex === 'graduation_year' || column.dataIndex === 'work_year' ? (
                                            <DatePicker style={{ width: '100%' }} picker="year" />
                                        ) : column.dataIndex === 'phone' || column.dataIndex === 'postal_code' ? (
                                            <Input />
                                        ) : (
                                            <Input />
                                        )}
                                        </Form.Item>
                                    </Col>
                                    ))}
                                </Row>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={isWaitting}>提出する</Button> &nbsp;&nbsp;
                                            <Button htmlType="button" onClick={handleModalCancel}>リセット</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </div>
                    <FooterLayout />
                </Content>
            </Layout>
        </Layout>
    );

}


export default CandidateList;