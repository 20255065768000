import React, { useState} from 'react';
import { 
    Layout, Col, Row, Avatar, Image, Badge, Dropdown, Space, 
    Modal, Form, Button, Input, message 
} from 'antd';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import MenuBar from "./Menu";
import { useNavigate } from 'react-router';
import { removeUserSession, deleteCookie, getLocalToken, getLocalStorageInfo, setLocalStorageInfo } from '../utils/Common';
import { MenuOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getCallData } from '../services/instanceCallService';

const globalConst = require('../constants/global_const');

//const { SubMenu } = Menu;
const { Header} = Layout;


function HeaderLayout(props){
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [cookies] = useCookies(['call_token']);
    const curToken = cookies.call_token ? cookies.call_token : getLocalToken();
    const [isWaiting, setIsWaiting] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();


    const userInfo = getLocalStorageInfo('curUser');
    // console.log('User info ', userInfo);

    let userName = userInfo?.name ?? "Empty";
    let userEmail = userInfo?.email ?? "Empty";
    let userRole = (userInfo?.is_admin) ? 'Admin' :  "User";
    const [cwForm] = Form.useForm();

    if(userName === "Empty" || userEmail === "Empty"){
        Logout();
    }

    const successMessage = (content) => {
		messageApi.open({
			type: 'success',
			content,
			className: 'custom-class',
			style: { marginTop: '20vh' },
		});
	};

    const Logout = async () => {
        let interceptor;
        setIsWaiting(true);
        try {
            // add interceptor request
            interceptor = axios.interceptors.request.use(
                (config) => {
                    // if token exist, add to headers Authorization Bearer token
                    if (curToken) {
                        config.headers['Authorization'] = `Bearer ${curToken}`;
                    }
                    return config;
                },
                (error) => {
                    // handle error request
                    return Promise.reject(error);
                }
            );
    
            const result = await axios.post(
                `${globalConst.API_URL}/auth/logout`,
                {},
                {
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': `Bearer ${curToken}`,
                    },
                }
            );
    
            // delete interceptor when request completed 
            axios.interceptors.request.eject(interceptor);
    
            successMessage(`System had logout, see you !!!`);
            localStorage.clear();
            setLocalStorageInfo('curUser', '');
            setLocalStorageInfo('call_token', '');
            setLocalStorageInfo('token', '');
            deleteCookie('call_token');
            removeUserSession();
            navigate('/login');
    
        } catch (error) {
            console.error('Error with logout:', error);
            setIsWaiting(false);
    
            // delete interceptor when error
            if (interceptor !== undefined) {
                axios.interceptors.request.eject(interceptor);
            }
            localStorage.clear();
            setLocalStorageInfo('curUser', '');
            setLocalStorageInfo('call_token', '');
            setLocalStorageInfo('token', '');
            deleteCookie('call_token');
            removeUserSession();
            navigate('/login');
        }
    };

    const showCWModal = () => {
        setOpen(true);
    };


    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpen(false);
    };

    const importLogData = () => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await getCallData(`/zoom-phone/import`);
            } catch (error) {
                console.error('Error import logs:', error);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
        return false;
    }

    const items = [
        {
          label: (
            <b style={{color: '#55f'}}>ユーザー名 : {userName}</b>
          ),
          key: '0',
        },
        {
            label: (
              <b style={{color: '#55f'}}>Eメール : {userEmail}</b>
            ),
            key: '1',
        },
        {
            label: (
                <b style={{color: '#55f'}}>役割 : {userRole}</b>
            ),
            key: '2',
        },
        /*{
            label: (
                
                userRole === 'Admin'　|| userRole === "User" ?
                <a onClick={importLogData} rel="Import logs from zoom" href="#">
                    <b style={{color: '#55f'}}>データ読み込み</b>
                </a>
                :
                <></>
                
            ),
            key: '6',
        },*/
        {
          label: (
            <a onClick={showCWModal} rel="" href="#">
              <b
                style={{ color: '#55f'}}
              >パスワードを変更する</b>
            </a>
          ),
          key: '3',
        },
        {
          type: 'divider',
        },
        {
            label: (
                <a  rel="" onClick={() => Logout()}>
                  <b style={{color: '#f55'}}>ログアウト</b>
                </a>
            ),
            key: '4',
          disabled: false,
          
        },
    ];    
 
    const handleCWFormSubmit = async (values) => {
        setIsWaiting(true);
        changeUserPW(values);
    };

    const changeUserPW = async (params) => {
        // console.log('data to change ', params);
        try {
            const result = await axios.post(
                `${globalConst.API_URL}/auth/change-password`,
                { ...params },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${curToken}`,
                    },
                },
            );

            successMessage(`Password had change successfull !!!`);
            setOpen(false);
            Logout();
        } catch (error) {
            console.error('Error fetching user data:', error);
            setIsWaiting(false);
        }
    }

    return (
        <Header className="header" style={{backgroundColor:'#fff'}} theme="light">
            <Row>
                <Col span={21}>
                {/* <MenuBar></MenuBar> */}
                </Col>

                <Col span={2} theme="light">
                    <Dropdown
                        menu={{
                        items,
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {userName}
                            <MenuOutlined />
                        </Space>
                        </a>
                    </Dropdown>
             
                </Col>

                <Col span={1} theme="light">
                    <span className="avatar-item">
                        <Button type="dashed" danger loading={isWaiting} onClick={() => Logout()}>
                        ログアウト
                        </Button>
                    </span>
                    
                </Col>
            </Row>
            {contextHolder}
            <Modal
                title="Change password"
                open={open}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    {...{
                        labelCol: { span: 8 },
                        wrapperCol: { span: 16 },
                    }}
                    form={cwForm}
                    onFinish={handleCWFormSubmit}
                >
                    <Form.Item
                        name="password"
                        label="新しいパスワード"
                        rules={[{ required: true }]}
                    >
                        <Input.Password
                            placeholder="新しいパスワード"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password_confirmation"
                        label="パスワードの確認"
                        rules={[{ required: true }]}
                    >
                        <Input.Password
                            placeholder="パスワードの確認"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={isWaiting}>提出する</Button>&nbsp;&nbsp;
                        <Button htmlType="button" onClick={handleCancel}>閉じる</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Header>
    );

};

export default HeaderLayout;