import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Space, Table, Tag, Dropdown, message, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { UserContext } from './UserContext';
import { getFullDateTime, getLocalToken } from '../../utils/Common';

const globalConst = require('../../constants/global_const');


const subjectItems = [
  {
    label: '自分のみ',
    value: 'me',
  },
  {
    label: '全員',
    value: 'all',
  },
];

const statusItems = [
    {
    label: 'すべて',
    value: '0',
    },
    
  {
    label: '予約中',
    value: '1',
  },
  {
    label: 'キャンセル',
    value: '2',
  },
  {
    label: '経過未対応',
    value: '3',
  },
  {
    label: 'コール済み',
    value: '4',
  },
];

const columns = [
  { title: '再コール日', dataIndex: 'recall_date', key: 'recall_date', width: 80,
    render: (recall_date) => {
      return(
        <>{recall_date}</>
      )
    }
   },
  { title: '顧客名', dataIndex: 'call_name', key: 'call_name', width: 120 },
  {
    title: '担当',
    dataIndex: 'call_status',
    key: 'call_status',
    render: (call_status) => (
      <Tag color={call_status === 'No Answer' ? 'volcano' : 'geekblue'}>
        {call_status}
      </Tag>
    ),
    width: 120
  },
  {
    title: 'ステータス',
    key: 'status',
    dataIndex: 'status',
    render: (status) => (
      <Tag color={status === 'No Answer' ? 'volcano' : 'geekblue'}>
        {status}
      </Tag>
    ),
    width: 150
  },
];

const ReCall = () => {
  let callessNum = '';
  const { userInfo } = useContext(UserContext);
  const [dataRecall, setDataRecall] = useState([]);
  const [cookies] = useCookies(["call_token"]);
  const curToken = cookies.call_token ? cookies.call_token : getLocalToken();

  if(userInfo){
    callessNum = userInfo.callee_number;
  }

  // console.log('call nomber ', callessNum);

  useEffect(() => {
    setDataRecall([]);
    if(callessNum && callessNum?.length > 0) {
      fetchRecall();
    }
  }, [callessNum]);
  
  const fetchRecall = async () => {
    const result = await axios.get(`${globalConst.CALL_SERVICE_URL}/call-log/${callessNum}/inbound`, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${curToken}`,
      },
    });
    setDataRecall(result.data.data);
  };
  
  // console.log('data recall ', dataRecall);


  const recallData = useMemo(() =>{
    if (dataRecall && Object.keys(dataRecall).length > 0) {
      return dataRecall.map((item) => ({
        key: item.id,
        type: item.call_type,
        call_status: item.call_status,
        recall_date: getFullDateTime(item.date_time),
        author: item.author_id,
        call_name: item.caller_name,
        status: item.status,
      }));
    }
  });


  return (
    <>
        <Table
            columns={columns}
            dataSource={recallData}
            rowKey="key"
            pagination={{ position: ['bottomRight'] }}
            scroll={{ y: 390 }}
        />
    </>
  );
}

export default ReCall;
