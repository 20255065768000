import React, { useContext, useMemo } from 'react';
import { Descriptions } from 'antd';
import { UserContext } from './UserContext';

export default function TwilioPhoneDetail() {
    const { userInfo } = useContext(UserContext);

    const userItems = useMemo(() => {
        return userInfo ? Object.entries(userInfo).map(([key, value]) => (
            <Descriptions.Item key={key.replace('_', ' ')} label={key.replace('_', ' ')}>
                {value}
            </Descriptions.Item>
        )) : [];
    }, [userInfo]);

    return (
        <div style={{ padding: '10px', height: '400px', backgroundColor: '#fff' }}>
            <Descriptions
                title="ユーザー情報"
                layout="vertical"
                bordered
                style={{ overflow: 'auto', height: 350 }}
            >
                {userItems}
            </Descriptions>
        </div>
    );
}
