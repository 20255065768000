import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  message,
  Col,
  Row,
  Layout,
  Tabs,
  Modal,
  Button,
  List,
  Avatar,
  Space,
} from "antd";
import axios from "axios";
import globalConst from "../../constants/global_const";
import { useCookies } from "react-cookie";
import { PhoneTwoTone } from "@ant-design/icons";

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import TwilioPhoneHeader from "./TwilioPhoneHeader";
import TwilioPhoneDetail from "./TwilioPhoneDetail";
import TwilioPhoneList from "./TwilioPhoneList";
import TwilioPhoneCall from "./TwilioPhoneCall";
import TwilioClientSection from "./TwilioClientSection";
import TwilioClientCount from "./TwilioClientCount";

import TwilioCallHistory from "./TwilioCallHistory";
import TwilioReCall from "./TwilioReCall";

import { UserProvider, UserContext } from "./UserContext";
import {
  Loading,
  getLocalToken,
  getLocalStorageInfo,
  setLocalStorageInfo,
} from "../../utils/Common";

const { Content } = Layout;

const CallTwillo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wgProjectData, setWgProjectData] = useState([]);
  const [projectId, setProjectId] = useState("");

  const [cookies] = useCookies(["call_token"]);
  const curToken = cookies.call_token ? cookies.call_token : getLocalToken();
  const [userList, setUserList] = useState([]);
  const [selectedKey, setSelectedKey] = useState(0);
  const [micStatus, setMicStatus] = useState('');

  const targetProject = getLocalStorageInfo("callProjectId");

  // Get list user from db start
  const [listUserPhone, setListUserPhone] = useState([]);

  const prodId = projectId || targetProject;

  const checkMicrophone = async () => {
    const permission = await navigator.permissions.query( { name: "microphone" } );
    console.log('Check mic ', permission);
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
      try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          setMicStatus('Microphone access granted.');
          stream.getTracks().forEach(track => track.stop()); // Dừng stream sau khi kiểm tra
      } catch (err) {
        setMicStatus('Microphone access denied: ' + err.message);
      }
    } else {
      setMicStatus('getUserMedia not supported on your browser!');
    }


  };

  useEffect(() => {
    const fetchCandidateData = async () => {
      setIsLoading(true);
      try {
        const result = await axios.get(
          `${globalConst.CALL_SERVICE_URL}/call-log/twillio`,
          {
            credentials: "include",
            mode: "cors",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${curToken}`,
            },
          }
        );

        setListUserPhone(result.data);
        setLocalStorageInfo('receipt-id', '');
        setLocalStorageInfo('phone-from', '');
        setLocalStorageInfo('phone-to', '');
        setLocalStorageInfo('phone-name', '');
        checkMicrophone();
        //console.log('phone list data ', result.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCandidateData();
  }, []);
  // Get list user from db end
 

  const showModal = () => {
    setModalTitle("ログ リストを取得するプロジェクトを選択します。");
    if (wgProjectData.length === 0) {
      //fetchData();
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!targetProject) {
      //showModal();
    }
  }, []);

  const handleNext = () => {
    setSelectedKey((selectedKey + 1));
  };

  const handlePrevious = () => {
      setSelectedKey(selectedKey <= 0 ? 0 : (selectedKey - 1));
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const result = await axios.get(
          `${globalConst.CALL_SERVICE_URL}/twillio-phone/active_phone_numbers`,
          {
            credentials: "include",
            mode: "cors",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${curToken}`,
            },
          }

        );

        setUserList(result.data.data || []);

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  let dataProject = [];
  if (wgProjectData.data) {
    wgProjectData.data.forEach((value) => {
      dataProject.push({
        id: value.id,
        title: value.name,
        workGroup: value.work_group_id,
      });
    });
  }

  
  const tabItems = [
    {
      key: "tabCallReport",
      label: "ヒストリー / History",
      children: (
        <Row key={"callHis"} gutter={[8, 16]}>
          <Col span={24}>
            <TwilioCallHistory user2Child={userList} />
          </Col>
        </Row>
      ),
    },
    {
      key: "recall",
      label: "再コール / Recall ",
      children: (
        <Row key={"callHis"} gutter={[8, 16]}>
          <Col span={24}>
            <TwilioReCall user2Child={userList} />
          </Col>
        </Row>
      ),
    },
  ];

  const onChangeTabs = (key) => {
    console.log(key);
  };

  const setPId = (pId) => {
    setProjectId(pId); // Sử dụng setProjectInfo từ UserContext
    setLocalStorageInfo("callProjectId", 0);
    setLocalStorageInfo("callProjectId", pId);
    setIsModalOpen(false);
  };

  const divLeftStyle = {
    paddingRight: "1px !important",
  };


  if (isLoading) {
    return (
      <Layout>
        <HeaderLayout />
        <Layout>
          <NavBarLayout />
          <Content style={{ padding: "0 24px 24px", height: "900px" }}>
            <Loading />
          </Content>
          <FooterLayout />
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout>
      <HeaderLayout />
      {contextHolder}
      <Layout>
        <NavBarLayout />
        <Content style={{ padding: "10px" }}>
          <UserProvider>
            <div style={{ minHeight: 760 }}>
              <h1>通常コールモード - Twillo</h1>
              <Button type="primary" onClick={showModal}>
                プロジェクトを選択
              </Button>
              &nbsp;&nbsp;
              <Button type="dashed" danger onClick={checkMicrophone}>Check Microphone</Button> 
              &nbsp;&nbsp;
              <label style={{color: '#52c41a'}}><b>{micStatus}</b></label>

              <Row gutter={[8, 16]} style={{paddingTop: '10px'}}>
                <Col span="15" key="colLeft">
                  <Row gutter={[8, 16]}>
                    <Col span={24}>
                      <TwilioPhoneHeader />
                    </Col>
                    <Col span={24}>
                      <TwilioPhoneDetail />
                    </Col>
                    <Col span={24}>
                      <TwilioPhoneList listUserPhone={listUserPhone} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
                    </Col>
                  </Row>
                </Col>

                <Col span="9" key="colRight">
                  <Row gutter={[8, 16]}>
                    <Col span={24}>
                      <div
                        style={{
                          padding: "0",
                          minHeight: "150px",
                          height: "auto",
                          backgroundColor: "#fff",
                          top: '50%',
                        }}
                      >
                        <TwilioPhoneCall handleNext={handleNext} handlePrevious={handlePrevious}/>
                      </div>
                    </Col>
                    <Col span={24} style={{ divLeftStyle }}>
                      <TwilioClientSection user2Child={userList} />
                    </Col>
                    <Col span={24} style={{ marginTop: "5px" }}>
                      <div
                        style={{
                          padding: "5px",
                          height: "420px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Tabs
                          defaultActiveKey="tabCallReport"
                          items={tabItems}
                          onChange={onChangeTabs}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Modal
                title={modalTitle}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
              >
                <div
                  id="scrollableDiv"
                  style={{
                    height: 300,
                    overflow: "auto",
                    padding: "0 16px",
                    border: "0px solid rgba(140, 140, 140, 0.35)",
                  }}
                >
                  <List
                    itemLayout="horizontal"
                    dataSource={dataProject}
                    renderItem={(item, index) => (
                      <List.Item onClick={() => setPId(item.id)}>
                        <List.Item.Meta
                          title={<a href="#">{item.title}</a>}
                          description={
                            <span>
                              ID を持つワークグループ : {item.workGroup}
                            </span>
                          }
                          key={index}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </Modal>
            </div>
          </UserProvider>
          <FooterLayout />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CallTwillo;
