import React, { useContext, useMemo, useRef, useState, useEffect } from 'react';
import { Descriptions, Input, Select, Button, Tooltip, message } from 'antd';
import { UserContext } from './UserContext';
import { getLabelByValue, } from '../../utils/Common';
import { postCallData } from "../../services/instanceCallService";
import { SaveOutlined } from "@ant-design/icons";

const statusList = [
    { label: "", value: " " },
    { label: '不在', value: '0' },
    { label: '興味なし', value: '1' },
    { label: '即断り', value: '3' },
    { label: '着信拒否', value: '4' },
    { label: '再アクション', value: '5' },
    { label: '面談アサイン', value: '6' },
    { label: 'イベントアサイン', value: '7' },
    { label: '電話番号不備', value: '8' },
    // { label: '接触前', value: '9' },
    { label: '追客中', value: '10' },
    { label: 'サービス停止', value: '11' },
    { label: 'サービス停止（メールOK）', value: '12' },
    { label: '前日確認OK', value: '13' },
    { label: 'キャンセル', value: '14' },
    { label: '日程変更', value: '15' },

];

const keysToShow = [
    'ID',
    'Name',
    'status',
    'furigana',
    'email',
    'phone',
    'address',
    'birthday',
    'gender',
    'postal_code',
    'university_name',
    'educational_stream',
    'department',
    'graduation_year',
    'desired_industry'
];


export default function PhoneDetail() {
    const { userInfo, author_id, setStatus, status } = useContext(UserContext);
    const textAreaRefs = useRef({});
    const [comments, setComments] = useState('');
    const curUserID = author_id ? author_id : 0;
    const [isWaiting, setWaiting] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [updatedStatus, setUpdatedStatus] = useState({});

    // Update the comments state when userInfo changes
    useEffect(() => {
        if (userInfo?.comments) {
            setComments(userInfo.comments);
        } else {
          setComments(null);
        }
    }, [userInfo]);

    const changeStatus = async (value) => {
        if (userInfo.candidateLogId === null) {
          // Update the status in local state
          setUpdatedStatus((prevStatus) => ({
            ...prevStatus,
            [userInfo.phone]: value,
          }));
    
          setStatus((prevStatus) => ({
            ...prevStatus,
            [userInfo.phone]: value,
          }));
          
          const updateModel = { author_id: curUserID, status: value == " " ? parseInt("") : parseInt(value) };
          // Update the logs
          UpdateLogs(updateModel, userInfo.phone);
          return;
        }
    
        // Update the status in local state
        setUpdatedStatus((prevStatus) => ({
          ...prevStatus,
          [userInfo.phone]: value,
        }));
    
        setStatus((prevStatus) => ({
          ...prevStatus,
          [userInfo.phone]: value,
        }));
    
        const updateModel = {
          author_id: curUserID,
          status: value == " " ? parseInt("") : parseInt(value),
          log_id: userInfo.candidateLogId,
        };
        // Update the logs
        UpdateLogs(updateModel, userInfo.phone);
    };
    
    const UpdateLogs = async (params, phoneNumber) => {
        setWaiting(true);
        try {
          await postCallData(`/call-log/${phoneNumber}/update`, params);
          messageApi.success("Call logs had updated success");
        } catch (error) {
          messageApi.error("Call logs had update failed ");
        } finally {
          setWaiting(false);
        }
    };

    const editComment = async () => {
        let canID = userInfo.candidateLogId;
    
        let phoneNumber = userInfo.phone;
        console.log(phoneNumber);

        const commentInput =
          textAreaRefs.current[phoneNumber].resizableTextArea.textArea.value;
    
        if (!canID) {
          const updateParam = {
            author_id: curUserID,
            comments: commentInput,
          };
          // Update the comments
          setWaiting(true);
          try {
            await postCallData(`/call-log/${phoneNumber}/update`, updateParam);
    
            messageApi.success("Update comments success");
          } catch (error) {
            messageApi.error("Call comments had update failed ");
            setWaiting(false);
          } finally {
            setWaiting(false);
          }
          return;
        }
    
        const updateParam = {
          author_id: curUserID,
          comments: commentInput,
          log_id: canID,
        };
    
        // Update the comments
        setWaiting(true);
        try {
          await postCallData(`/call-log/${phoneNumber}/update`, updateParam);
    
          messageApi.success("Update comments success");
        } catch (error) {
          messageApi.error("Call comments had update failed ");
          setWaiting(false);
        } finally {
          setWaiting(false);
        }
      };
    
    const userItems = userInfo
        ? Object.entries(userInfo)
              .filter(([key]) => keysToShow.includes(key)) // Filter based on keysToShow
              .map(([key, value]) => (
                  <Descriptions.Item
                      key={key.replace('_', ' ')}
                      label={
                          <b style={{ color: 'hotpink' }}>
                              {{
                                  ID: 'NO.',
                                  Name: '名前',
                                  status: 'ステータス・メモ',
                                  furigana: 'フリガナ',
                                  email: 'メールアドレス',
                                  phone: 'Tel',
                                  address: '住所',
                                  birthday: '生年月日',
                                  gender: '性別',
                                  postal_code: '郵便番号',
                                  university_name: '学校名',
                                  educational_stream: '文理',
                                  department: '学部',
                                  graduation_year: '卒業年度',
                                  desired_industry: '志望業界',
                              }[key] || ''}
                          </b>
                      }
                  >
                      {key === 'status' ? (
                            <>
                            <Select
                                value={
                                    updatedStatus[userInfo.phone] ||
                                    status[userInfo.phone] ||
                                    userInfo.status
                                }
                                onChange={(value) => changeStatus(value)}
                                options={statusList}
                                loading={isWaiting}
                                style={{ width: "215px" }}
                            />
                            <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                <Input.TextArea
                                    ref={(el) => (textAreaRefs.current[userInfo.phone] = el)}
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    rows={1}
                                    style={{ width: "215px" }}
                                />
                                <Tooltip title="Save note">
                                    <Button
                                        onClick={() => editComment()}
                                        icon={<SaveOutlined />}
                                        loading={isWaiting}
                                    />
                                </Tooltip>
                            </div>
                        </>
                        ) : (
                            <label style={{ color: '#55f' }}>
                                <b>
                                    {key === 'status'
                                        ? getLabelByValue(statusList, value)
                                        : value}
                                </b>
                            </label>
                        )}

                  </Descriptions.Item>
              ))
              .reduce((acc, item, index, arr) => {
                // If status is not already in the list, add it in the third position
                if (arr[index].key === 'status') {
                  return [
                    ...acc.slice(0, 2), // First two items remain the same
                    arr[index], // Insert the status as the third item
                    ...acc.slice(2), // Add the remaining items after the status
                  ];
                }
                return acc.concat(item); // Otherwise just push the rest of the items
              }, [])
        : [];

    return (
        <div style={{ padding: '10px', height: '420px', backgroundColor: '#fff' }}>
            {contextHolder}
            <Descriptions
                title="ユーザー情報"
                layout="vertical"
                bordered
                style={{ overflow: 'auto', height: 350 }}
            >
                {userItems}
            </Descriptions>
        </div>
    );
}
