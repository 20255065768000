import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Table,
  Tag,
  Layout,
  message,
  Spin,
  Space,
  DatePicker,
  Button,
  Select,
} from "antd";
import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { secondsToHHMMSS } from "../../utils/Common";
import { getCallData } from "../../services/instanceCallService";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { getDataUser } from "../../services/instanceUserService";
import { UserContext } from "../../components/call/UserContext";

const { Content } = Layout;

const CallReportZoom = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setWaiting] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [reportData, setReportData] = useState([]);
  const [updatedUser, setUpdatedUser] = useState([]);
  const [sysUserData, setSysUserData] = useState([]);
  const { author, setUser } = useContext(UserContext);

  const curUserID = author?.id;
  const curUserName = author?.name;
  const isAdmin = author?.is_admin;

  let [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("filter");
  const type = searchParams.get("type");
  let urlAPI = '/call-log/me?';
  const user_id = searchParams.get("user_id");

  const optCurUsers = [
    {
      label: curUserName,
      value: curUserID,
    },
  ];

  const handleUpdateUser = (user_id, user_name) => {
    // Add or update the user in updatedUser state
    setUpdatedUser((prevState) => ({
      ...prevState,
      [user_id]: { label: user_name, value: user_id },
    }));
  };

  const fetchSysUser = async () => {
    if (isAdmin) {
      try {
        const result = await getDataUser("/users");
        setSysUserData(result.data.data || []);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        //setIsLoading(false);
      }
    } else {
      setSysUserData([]);
    }
  };

  const optSysUsers = useMemo(() => {
    if (Array.isArray(sysUserData) && sysUserData.length > 0) {
      return sysUserData.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
    return []; // Default to empty array
  }, [sysUserData]);


  useEffect(() => {
    const fetchReport = async () => {
      setIsLoading(true);

      try {
        if (user_id){
          urlAPI = `${urlAPI}&user_id=${user_id}`;
          // Get the user details from optSysUsers or optCurUsers
          const user = optSysUsers.find((u) => u.value === user_id) || [];

          if (user) {
            handleUpdateUser(user.value, user.label);
          }
        }
        if (type === "date" && date) {
          const result = await getCallData(`${urlAPI}&date=${date}`);
          setReportData(result.data);
        } else if (type === "month" && date) {
          const result = await getCallData(`${urlAPI}&month=${date}`);
          setReportData(result.data);
        } else {
          const result = await getCallData(`${urlAPI}`);
          setReportData(result.data);
        }
      } catch (error) {
        console.log("Error fetching workgroups:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchReport();
    fetchSysUser();
  }, [searchParams]);

  const handleDateChange = (date, dataString) => {
    if (date) {
      if(user_id){
          setSearchParams({ filter: date?.format("YYYY-MM-DD"), type: "date", user_id: user_id });
      } else {
          setSearchParams({ filter: date?.format("YYYY-MM-DD"), type: "date" });
      }
    } else {
      searchParams.delete("filter");
      searchParams.delete("type");
      searchParams.delete("user_id");
      setSearchParams(searchParams);
    }
  };

  const handleChangeMonth = (date, dataString) => {
    if (date) {
      if(user_id){
          setSearchParams({ filter: date?.format("YYYY-MM"), type: "month", user_id: user_id });
      } else {
          setSearchParams({ filter: date?.format("YYYY-MM"), type: "month" });
      }
    } else {
      searchParams.delete("filter");
      searchParams.delete("type");
      searchParams.delete("user_id");
      setSearchParams(searchParams);
    }
  };

  const handleChangeUser = (user_id, dataString) => {
    if (user_id) {
      setSearchParams({ user_id: user_id});
      // Get the user details from optSysUsers or optCurUsers
      const user = optSysUsers.find((u) => u.value === user_id) || optCurUsers[0];

      if (user) {
        handleUpdateUser(user.value, user.label);
      }
    } else {
      searchParams.delete("user_id");
      searchParams.delete("filter");
      searchParams.delete("type");
      setSearchParams(searchParams);
    }
  };

  const columns = [
    {
      title: "名前",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleChangeUser(record.id)}
        >
          <Tag color={"#77f"}>{record.name}</Tag>
        </Button>
      ),
      width: 120,
    },
    {
      title: "架電数",
      dataIndex: "hit_call_per_day",
      key: "hit_call_per_day",
      width: 90,
      render: (hit_call_per_day) => (
        <Tag color={"#52c41a"}>{hit_call_per_day}</Tag>
      ),
    },
    {
      title: "通電数",
      dataIndex: "call_per_day",
      key: "call_per_day",
      width: 90,
      render: (call_per_day) => <Tag color={"#52c41a"}>{call_per_day}</Tag>,
    },
    {
      title: "アポ数",
      key: "number_of_appointment",
      dataIndex: "number_of_appointment",
      width: 150,
      render: (number_of_appointment) => (
        <Tag color={"#52c41a"}>{number_of_appointment}</Tag>
      ),
    },
    {
      title: "つながり率",
      key: "called_rate",
      dataIndex: "called_rate",
      width: 120,
      render: (called_rate) => (
        <Tag color={"#f55"}>{parseFloat(called_rate).toFixed(2)} %</Tag>
      ),
    },

    {
      title: "合計通話",
      dataIndex: "total_duration",
      key: "total_duration",
      render: (connection_date) => (
        <Tag color={"#52c41a"}>{secondsToHHMMSS(connection_date)}</Tag>
      ),
      width: 130,
    },
  ];

  const repData = (reportData) => {
    if (!Array.isArray(reportData)) {
      throw new TypeError("reportData must be an array");
    }

    return reportData.map((item, index) => ({
      key: index + 1,
      id: item.user_id,
      name: item.callerName,
      connection_date: item.call_date,
      hit_call_per_day: item.hitPerDay,
      call_per_day: item.hitAppointment,
      number_of_appointment: item.callPerStatus,
      called_rate: item.calledRate || 0,
      total_duration: item.totalDuration,
    }));
  };

  const exportCSVData = () => {
    if (reportData) {
      const csvDataExp = reportData.map((row) => ({
        名前: row.callerName,
        架電数: row.hitPerDay || 0,
        通電数: row.hitAppointment || 0,
        アポ数: row.callPerStatus || 0,
        つながり率:
          row.calledRate == null
            ? 0 + ".0%"
            : parseFloat(row.calledRate).toFixed(1) + "%",
        合計通話:
          (row.totalDuration &&
            row.totalDuration != 0 &&
            `${Math.floor(row.totalDuration / 60)}:${
              row.totalDuration % 60 < 10 ? "0" : ""
            }${row.totalDuration % 60}`) ||
          0,
      }));
      const now = dayjs();
      const formattedDate = now.format("YYYY-MM-DD");
      const bom = "\uFEFF";
      const csv = bom + Papa.unparse(csvDataExp, { header: true });
      const blob = new Blob([csv], { type: "text/csv;charset=ansi" });
      saveAs(blob, date == null ? formattedDate : date + "-Report.csv");
    } else {
      messageApi.error("Error fetching candidates");
      setWaiting(false);
    }
  };

  return (
    <Layout>
      <HeaderLayout />
      {contextHolder}
      <Layout>
        <NavBarLayout />
        <Content style={{ padding: "10px" }}>
          <div style={{ minHeight: 780 }}>
            {isLoading ? (
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                }}
              >
                <Spin size="large" />
                <div className="ms-3 mt-2">読み込み中</div>{" "}
              </div>
            ) : (
              <>
                <Space>
                  <h1>レポート</h1>
                  {isAdmin ? (<Select
                    showSearch
                    placeholder="応対者"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: 230 }}
                    onChange={(value) => handleChangeUser(value)}
                    value={updatedUser[user_id]?.value || optCurUsers[0]}
                    options={[...(optSysUsers || [])]}
                    loading={isWaiting}
                  />) : (<></>)}
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={handleDateChange}
                    format="YYYY-MM-DD"
                    placeholder="日にちを選択"
                    value={
                      type === "date" && date ? dayjs(date, "YYYY-MM-DD") : null
                    }
                  />
                  <DatePicker
                    onChange={handleChangeMonth}
                    picker="month"
                    format="YYYY-MM"
                    placeholder="月を選択"
                    value={
                      type === "month" && date ? dayjs(date, "YYYY-MM") : null
                    }
                  />
                  <Button
                    type="dashed"
                    danger
                    loading={isWaiting}
                    onClick={exportCSVData}
                  >
                    CSV Export
                  </Button>
                </Space>

                <Table
                  columns={columns}
                  dataSource={repData(reportData)}
                  rowKey="key"
                  pagination={false}
                  scroll={{ y: 600, x: 680 }}
                  loading={isWaiting}
                />
              </>
            )}
          </div>
          <FooterLayout />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CallReportZoom;
