import React, { useContext } from 'react';
import { Descriptions } from 'antd';
import { UserContext } from './UserContext';
import { PhoneTwoTone } from '@ant-design/icons';
import {
  setLocalStorageInfo,
} from "../../utils/Common";


export default function TwilioPhoneHeader() {
  const { userInfo } = useContext(UserContext);

  let items = [];

  if (userInfo) {
    const entries = Object.entries(userInfo);
    const zoomPhone = 'zoomphonecall://';
    
    for (let i = 0; i < entries.length && i < 6; i++) {
      const [key, value] = entries[i];
      if(key === 'phone') {
        setLocalStorageInfo("phone-to", JSON.stringify(value));
      }
      items.push({
        key,
        label: key,
        children: (key === 'phone') ? <a href={`${zoomPhone}${value}`}>{value}  <PhoneTwoTone style={{ fontSize: '20px'}} twoToneColor="#52c41a"/></a> : value,
      });
    }
  }

  return (
    <div style={{ padding: '10px', height: '150px', backgroundColor: '#fff' }}>
      <Descriptions title="ユーザー詳細" items={items} />
    </div>
  );
}
