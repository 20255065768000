import React, { useState } from "react";
import { Table } from "antd";

export default function WorkgroupData({ workGroup, setSelectedWorkGroup }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedWorkGroup(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          const newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 === 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          const newSelectedRowKeys = changeableRowKeys.filter(
            (_, index) => index % 2 !== 0
          );
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const columns = [
    {
      title: "会社",
      dataIndex: "name",
    },
  ];

  const data = workGroup.map((record) => ({
    key: record.id,
    id: record.id,
    name: record.name,
  }));

  return (
    <Table
      rowSelection={{ ...rowSelection, type: "radio" }}
      columns={columns}
      dataSource={data}
      pagination={{ position: ["none", "none"] }}
    />
  );
}
