import React, { useCallback, useState, useEffect, } from 'react';
import { Table, Tag, Dropdown, Menu, Space, Layout, message } from 'antd';
import { CloseOutlined, DownOutlined, CheckOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { Loading } from '../../utils/Common';


const { Content } = Layout;


const items = [
  { key: 'rec0', label: 'すべて', icon: <PlusOutlined /> },
  { key: 'rec1', label: '予約中', icon: <CloseOutlined /> },
  { key: 'rec2', label: 'キャンセル', icon: <CheckOutlined /> },
  { key: 'rec3', label: '経過未対応', icon: <EditOutlined /> },
  { key: 'rec4', label: 'コール済み', icon: <CloseOutlined /> },
];

let items_1 = [
    { key: 'prod0', label: 'すべて', icon: <PlusOutlined /> },
    { key: 'prod1', label: '予約中', icon: <CloseOutlined /> },
    { key: 'prod2', label: 'キャンセル', icon: <CheckOutlined /> },
    { key: 'prod3', label: '経過未対応', icon: <EditOutlined /> },
    { key: 'prod4', label: 'コール済み', icon: <CloseOutlined /> },
];
  
const handleCreateChildTask = (receptionist) => {
  console.log('record', receptionist);
};

const CallRecording = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
  
    const handleMenuClick = useCallback(({ key }, receptionist) => {
        if (key === '1') handleCreateChildTask(receptionist);
        console.log(`Clicked menu item ${key} for receptionist ${receptionist}`);
    }, []);

    const showSuccessMessage = () => {
        messageApi.open({
          type: 'success',
          content: 'Create new call',
          className: 'custom-class',
          style: { marginTop: '20vh' },
        });
    };
    
    useEffect(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
    }, []);

    
    const columns = [
        { title: 'Type', dataIndex: 'type', key: 'type', width: 80 },
        { title: 'Date&Time', dataIndex: 'date_time', key: 'date_time', width: 120 },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            width: 90,
        },
        {
            title: 'Duration of call',
            key: 'call_duration',
            dataIndex: 'call_duration',
            width: 150,
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag color={status ? 'volcano' : 'geekblue'}>
                {status ? 'Active' : 'Inactive'}
                </Tag>
            ),
            width: 130,
        },
    ];

    const hisData = [
        {
        key: '1',
        type: 'call',
        date_time: '2024-06-24',
        author: 'author 1',
        call_duration: 'call duration 1',
        status: 1,
        },
    ];

    if (isLoading) {
        return (
          <Layout>
            <HeaderLayout />
            <Layout>
              <NavBarLayout />
              <Content style={{ padding: '0 24px 24px', height: '900px' }}>
                <Loading />
              </Content>
              <FooterLayout />
            </Layout>
          </Layout>
        );
    }
    
    return (
        <Layout>
            <HeaderLayout />
            {contextHolder}
            <Layout>
                <NavBarLayout />
                <Content style={{ padding: '10px'}}>
                    <div style={{ minHeight: 780 }}>
                        <h1>通話録音ログ / Call Recording Logs</h1>
                        <Table
                            columns={columns}
                            dataSource={hisData}
                            rowKey="key"
                            pagination={false}
                            scroll={{ y: 390, x: 680 }}

                        />
                    </div>
                    <FooterLayout />
                </Content>
            </Layout>
        </Layout>
    );

};

export default CallRecording;
