import React from 'react';
import MainLayout from '../../layout/MainLayout';

import { getToken, getUser, removeUserSession, setUserSession } from '../../utils/Common';

import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
const { Header, Content, Sider } = Layout;

const items1 = ['1', '2', '3'].map((key) => ({
  key,
  label: `nav ${key}`,
}));

const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
  const key = String(index + 1);
  return {
    key: `sub${key}`,
    icon: React.createElement(icon),
    label: `subnav ${key}`,
    children: new Array(4).fill(null).map((_, j) => {
      const subKey = index * 4 + j + 1;
      return {
        key: subKey,
        label: `option${subKey}`,
      };
    }),
  };
});

function HomeAdmin(props) {
  const {
    call_token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();



  // handle click event of logout button
  console.log('Current token => ' + sessionStorage?.getItem('call_token') );

  if(sessionStorage.getItem('user') != 'undefined') {
    let curUser = getUser();
    let curToken = getToken();
    localStorage.setItem('curUser', JSON.stringify(curUser));
    localStorage.setItem('call_token', JSON.stringify(curToken));

    console.log('get user and token from session', sessionStorage?.getItem('user'));
  } else {
    console.log('get user and token from state', JSON.parse(localStorage.getItem('call_token')));
    //reset session token
    removeUserSession();
    setUserSession(JSON.parse(localStorage.getItem('call_token')), JSON.parse(localStorage.getItem('curUser')));
  }
   
  const handleLogout = () => {
    removeUserSession();    
    props.history.push('/login');
  }

  return (
    <MainLayout>
      <Layout>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
        </Layout>

        <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            Content
        </Content>
      </Layout>
    </MainLayout>
  );

}
 
export default HomeAdmin;