import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import {
  Col,
  Row,
  Table,
  Space,
  Tag,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Transfer,
  message,
  Upload,
  Divider,
  Spin,
} from "antd";
import {
  Loading,
  getFullDate,
  getToken,
  getLabelByValue,
} from "../../utils/Common";
import { useCookies } from "react-cookie";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { getCallData } from "../../services/instanceCallService";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import {
  deleteManagementData,
  getManagementData,
  postManagementData,
  putManagementData,
} from "../../services/instanceManagementService";
import { postReport } from "../../services/instanceReportServices";

const statusList = [
  { label: "Unknow", value: "NULL" },
  { label: "不在", value: "0" },
  { label: "興味なし", value: "1" },
  { label: "即断り", value: "3" },
  { label: "着信拒否", value: "4" },
  { label: "再アクション", value: "5" },
  { label: "面談アサイン", value: "6" },
  { label: "イベントアサイン", value: "7" },
  { label: "電話番号不備", value: "8" },
  // { label: "接触前", value: "9" },
  { label: "追客中", value: "10" },
  { label: "サービス停止", value: "11" },
  { label: "サービス停止（メールOK）", value: "12" },
  { label: "前日確認OK", value: "13" },
  { label: "キャンセル", value: "14" },
  { label: "日程変更", value: "15" },
];

function WgProjectData({ selectedWorkGroup, workGroup, listUser }) {
  const [wgProjectData, setWgProjectData] = useState([]);
  const [cookies] = useCookies(["call_token"]);
  const curToken = cookies.call_token ? cookies.call_token : getToken();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState("");

  const successMessage = (content) => {
    messageApi.open({
      type: "success",
      content,
      className: "custom-class",
      style: { marginTop: "20vh" },
    });
  };

  const optData = useMemo(() => {
    return workGroup?.map(({ id, name }) => ({ value: id, label: name })) || [];
  }, [workGroup]);

  const typeData = [
    { id: "0", name: "to C" },
    { id: "1", name: "to B" },
  ];
  
  const optTypeData = useMemo(() => {
    return typeData?.map(({ id, name }) => ({ value: id, label: name })) || [];
  }, [typeData]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (selectedWorkGroup.length !== 0) {
        const res = await getManagementData(
          `/work_groups?wrk_groups=${selectedWorkGroup}`
        );

        setWgProjectData(res.data);
      }
    } catch (error) {
      setWgProjectData([]);
      console.error("Error fetching project data:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedWorkGroup]);

  const transferData = listUser.map((user) => ({
    key: user.id.toString(),
    title: user.name,
    description: `User ${user.name}`,
    chosen: Math.random() > 0.5,
  }));

  const dataProject = useMemo(() => {
    const processedData = [];
    const projects = wgProjectData[0]?.projects || [];
    projects.forEach((project) => {
      processedData.push({
        id: project.id,
        name: project.name,
        work_group_name: wgProjectData[0]?.name,
        work_group_id: wgProjectData[0]?.id,
        is_enterprise: project.is_enterprise,
        created_at: project.created_at,
        assign_user: project.assigned_users.map((user) => ({
          id: user.user_id,
          name: user.name,
        })),
      });
    });
    return processedData;
  }, [wgProjectData]);

  const onFinish = async (values) => {
    setIsWaiting(true);
    try {
      if (values.projectId === -1) {
        if (targetKeys.length === 0) {
          message.error("ユーザーを追加してください！");
          return;
        }

        let assignUser = targetKeys.filter((el) => el != 0);
        values.assignUser = assignUser;
        await addNewProject(values);
      } else {
        let assignUser = targetKeys.filter((el) => el != 0);
        values.assignUser = assignUser;
        await editProject(values.projectId, values);
      }
    } catch (error) {
      console.error("Error adding new project:", error);
    } finally {
      setIsWaiting(false);
    }
  };

  const addNewProject = async (params) => {
    const wrId = params.work_group_id;

    const projectParam = {
      name: params.name,
      work_group_id: params.work_group_id,
      is_enterprise: params.is_enterprise,
    };

    let csvImportParams = new FormData();
    fileList.forEach((file) => {
      csvImportParams.append("csv_file[]", file);
    });

    try {
      const res = await postManagementData(
        `/work_groups/${wrId}/projects`,
        projectParam
      );

      if (res.success) {
        const newProjectId = res.data.id;
        // add assign user
        await postManagementData(`/projects/${newProjectId}/users`, {
          user_id: params.assignUser,
        });

        csvImportParams.append("project_id", newProjectId);
        csvImportParams.append("is_enterprise", projectParam.is_enterprise);

        // add csv file
        await postReport(`/candidates/importCSV`, csvImportParams);
      }
      message.success("プロジェクトが追加されました");
    } catch (error) {
      console.error("Error adding new project:", error);
      message.error(error.response?.data?.data?.name);
    } finally {
      fetchData();
      setIsModalOpen(false);
    }
  };

  const editProject = async (pId, params) => {
    let assignUserParam = {
      user_id: params.assignUser,
    };
    let projectParam = {
      name: params.name,
      is_enterprise: params.is_enterprise,
      work_group_id:
        typeof params.work_group_id === "number"
          ? params.work_group_id
          : params.wgSelectId,
    };

    let importFromCsvParam = new FormData();
    fileList.forEach((file) => {
      importFromCsvParam.append("csv_file[]", file);
    });

    try {
      const res = await putManagementData(`/projects/${pId}`, projectParam);

      if (res.success) {
        const newProjectId = res.data.id;
        // update assign user
        await postManagementData(
          `/projects/${newProjectId}/users`,
          assignUserParam
        );

        importFromCsvParam.append("project_id", pId);
        importFromCsvParam.append("is_enterprise", projectParam.is_enterprise);

        if (fileList.length > 0) {
          // update csv file
          await postReport(`/candidates/importCSV`, importFromCsvParam);
        }
      }

      message.success("プロジェクトが編集されました");
    } catch (error) {
      console.error("Error adding new project:", error);
      message.error(error.response?.data?.data?.name);
    } finally {
      fetchData();
      setIsModalOpen(false);
    }
  };

  const deleteProject = async (pId) => {
    setDeleteProjectId(pId);
    try {
      await deleteManagementData(`/projects/${pId}`);

      successMessage("プロジェクトが削除されました");
    } catch (error) {
      message.error("プロジェクトの削除に失敗しました");
      setDeleteProjectId("");
    } finally {
      setDeleteProjectId("");
      fetchData();
    }
  };

  const showModal = (projectDetail) => {
    setModalTitle(projectDetail.id ? "プロジェクトを編集" : "プロジェクト登録");

    form.resetFields();
    if (projectDetail.id) {
      setTargetKeys(
        projectDetail.assign_user.map((user) => user.id.toString())
      );

      form.setFieldsValue({
        name: projectDetail.name,
        work_group_id: projectDetail.work_group_id,
        projectId: projectDetail.id,
        is_enterprise: projectDetail.is_enterprise,
      });
      form.setFieldsValue({ wgSelectId: projectDetail.work_group_id });
      setIsDisable(true);
    } else {
      form.setFieldsValue({ projectId: -1 });
      setIsDisable(false);
    }

    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleExportProject = async (pId, pName) => {
    const canData = await getCandidateByProject(pId);

    if (canData) {
      const csvDataExp = canData.map((row, index) => ({
        NO: index + 1,
        同意日時: row.agreed_at,
        姓名: row.candidate_name,
        フリガナ: row.furigana,
        性別: row.gender,
        学校種類: row.school_type,
        大学名: row.university_name,
        文理: row.educational_stream,
        学部学科: row.department,
        卒業年度: row.graduation_year,
        就職希望年度: row.work_year,
        志望業界: row.desired_industry,
        email: row.email,
        電話番号: row.candidate_phone,
        郵便番号: row.postal_code,
        住所: row.address,
        生年月日: row.birthday,
        架電可否: "",
        架電実施可否: "",
        架電日: row.last_call,
        架電回数: row.total_call,
        架電ステータス: getLabelByValue(
          statusList,
          row.status ? row.status.toString() : ""
        ),
        再アクション日時: "",
        重複チェック: "",
        サービス停止重複: "",
        ZOOMphoneURL: "",
        架電対応者: row.user_name,
      }));
      const bom = "\uFEFF";
      const csv = bom + Papa.unparse(csvDataExp, { header: true });
      const blob = new Blob([csv], { type: "text/csv;charset=ansi" });
      saveAs(blob, pName + "-CSV.csv");
    } else {
      messageApi.error("Error fetching candidates");
      setIsWaiting(false);
    }
  };

  const getCandidateByProject = async (projectId) => {
    try {
      setIsExporting(projectId);
      const res = await getCallData(`/call-log?project_id=${projectId}`);
      return res.data;
    } catch (error) {
      messageApi.error("Error fetching candidates :", error);
      setIsExporting("");
    } finally {
      messageApi.success("CSVファイルがダウンロードされました");
      setIsExporting("");
    }
  };

  const columns = [
    {
      title: "名前",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    { title: "会社", dataIndex: "work_group_name", key: "work_group_name" },
    // { 
    //   title: "ロール", 
    //   dataIndex: "is_enterprise", 
    //   key: "is_enterprise",
    //   render: (value) => {
    //     // Find the label for the current value
    //     const typeLabel = optTypeData.find((option) => option.value === String(value))?.label || "不明"; // Default to "不明" (unknown) if not found
    //     return <span>{typeLabel}</span>;
    //   }
    // },    
    {
      title: "割り当てられたユーザー",
      dataIndex: "assign_user",
      key: "assign_user",
      render: (assign_user) => (
        <>
          {assign_user.map((user) => (
            <Tag color="blue" key={user.id}>
              {user.name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "登録日",
      dataIndex: "created_at",
      key: "created_at",
      render: getFullDate,
    },
    {
      title: "アクション",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => showModal(record)}>
            編集
          </Button>
          <Button
            type="link"
            onClick={() => confirmDeleteProject(record.id)}
            loading={deleteProjectId === record.id ? true : false}
          >
            {deleteProjectId === record.id ? "" : "削除"}
          </Button>
        </Space>
      ),
    },
    {
      title: "Export CSV",
      dataIndex: "exportCsv",
      key: "exportCsv",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleExportProject(record.id, record.name)}
          loading={isExporting === record.id ? true : false}
        >
          {record.name}
        </Button>
      ),
      width: 150,
    },
  ];

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const confirmDeleteProject = (id) => {
    Modal.confirm({
      title: `Are you sure delete this project ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => deleteProject(id),
    });
  };

  const props = {
    beforeUpload: (file) => {
      return new Promise((resolve, reject) => {
        if (file && file.size > 10485760) {
          message.error("File size must be smaller than 10MB");
          reject();
        } else {
          resolve();
        }
      });
    },
    fileList,
    customRequest: ({ file, onSuccess }) => {
      setFileList((prevFile) => [...prevFile, file]);
      onSuccess("ok");
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };

  return (
    <div>
      {contextHolder}
      {isLoading ? (
        <div style={{
          position: "fixed",
          inset: 0,
          backgroundColor: "rgba(0, 0, 0, 0)",
          zIndex: 9999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <div
            style={{
              height: "20vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              backgroundColor: "transparent",
            }}
          >
            <Spin size="large" />
            <div className="ms-3 mt-2">読み込み中</div>{" "}
          </div>
        </div>
      ) : (
        <>
          <div>
            <Row style={{ marginBottom: "10px" }}>
              <Col span={24}>
                <Button type="primary" onClick={showModal}>
                  新しいプロジェクトを追加
                </Button>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={dataProject || []}
                  rowKey="id"
                  pagination={{ pageSize: 10, position: ["bottomCenter"] }}
                  loading={isLoading}
                />
              </Col>
            </Row>
          </div>

          <Modal
            title={modalTitle}
            open={isModalOpen}
            onOk={form.submit}
            onCancel={handleModalCancel}
            confirmLoading={isWaiting}
          >
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="name"
                label="プロジェクト名"
                rules={[
                  {
                    required: true,
                    message: "プロジェクト名を入力してください",
                  },
                ]}
              >
                <Input placeholder="プロジェクト名" />
              </Form.Item>
              <Form.Item
                name="work_group_id"
                label="会社 "
                rules={[{ required: true, message: "会社を選択してください" }]}
              >
                <Select
                  showSearch
                  disabled={isDisable}
                  placeholder="会社を選択してください"
                  options={optData}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              {/* <Form.Item
                name="is_enterprise"
                label="ロール "
                rules={[{ required: true, message: "ロールを選択してください" }]}
              >
                <Select
                  showSearch
                  disabled={isDisable}
                  placeholder="ロールを選択してください"
                  options={optTypeData}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item> */}
              <Form.Item
                name="csv_list"
                label="CVS 情報元"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  return e && e.fileList;
                }}
                rules={[
                  {
                    required: isDisable ? false : true,
                    message: "CSVファイルを入れてください!",
                  },
                  {
                    validator: async (_, fileList) => {
                      return new Promise((resolve, reject) => {
                        if (fileList && fileList.size > 10485760) {
                          reject();
                        } else {
                          resolve();
                        }
                      });
                    },
                  },
                ]}
              >
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>
                    クリックしてアップロード
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item name="projectId" hidden>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item name="wgSelectId" hidden>
                <Input />
              </Form.Item>
              <Divider orientation="left">ユーザーの割り当て</Divider>
              <Transfer
                dataSource={transferData}
                titles={["Available", "Assigned"]}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                render={(item) => item.title}
                listStyle={{
                  width: 250,
                  height: 300,
                }}
              />
            </Form>
          </Modal>
        </>
      )}
    </div>
  );
}

export default WgProjectData;
