import React, { useState, useEffect, } from 'react';
import { Table, Tag, Layout } from 'antd';

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { Loading } from '../../utils/Common';


const { Content } = Layout;

const columns = [
    { title: 'フルネーム', dataIndex: 'name', key: 'name', width: 120 },
    {
        title: 'ステータス',
        dataIndex: 'status',
        key: 'status',
        render: (status) => (
            <Tag color={status ? 'volcano' : 'geekblue'}>
            {status ? 'Active' : 'Inactive'}
            </Tag>
        ),
        width: 120,
    },
];

function UserGroup() {
    // const [isLoading, setIsLoading] = useState(false);

    // useEffect(() => {
    //     setTimeout(() => {
    //       setIsLoading(false);
    //     }, 3000);
    // }, []);

    
    const groupData = [
        {
            key: '1',
            id: '1',
            name: 'Admin',
            status: 1,
        },
        {
            key: '2',
            id: '2',
            name: 'User',
            status: 1,
        },
    ];

    // if (isLoading) {
    //     return (
    //       <Layout>
    //         <HeaderLayout />
    //         <Layout>
    //           <NavBarLayout />
    //           <Content style={{ padding: '0 24px 24px', height: '900px' }}>
    //             <Loading />
    //           </Content>
    //           <FooterLayout />
    //         </Layout>
    //       </Layout>
    //     );
    // }

    return (
        <Layout>
            <HeaderLayout />
            <Layout>
                <NavBarLayout />
                <Content style={{ padding: '0 24px 24px', height: '900px' }}>
                    <div style={{ minHeight: 780 }}>
                        <h1>ユーザーグループ </h1>
                        <Table
                            columns={columns}
                            dataSource={groupData}
                            rowKey="key"
                            pagination={{ position: ['bottomRight'] }}
                            scroll={{ y: 390, x: 680 }}

                        />
                    </div>
                    <FooterLayout />
                </Content>
            </Layout>
        </Layout>                    
    );
}

export default UserGroup;