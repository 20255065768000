import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import MainRoutes from "./MainRoutes";
import { UserProvider } from "./components/call/UserContext";

function App() {
  return (
    <Router>
      <UserProvider>
        <MainRoutes></MainRoutes>
      </UserProvider>
    </Router>
  );
}

export default App;
