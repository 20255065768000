import React from 'react';
import { Col, Row } from 'antd';
import PhoneKeyboard from './PhoneKeyboard';

function ClientSection () {
    const divRightStyle = {
        paddingLeft: '0 !important',
    };
    
    return(
        <>
        <div style={{ padding: '0', height: '420px', backgroundColor: '#fff' }}>
            <Row>
                <Col span={24}>
                    <div style={{ padding: '10px', height: '40px', width: '100%', backgroundColor: '#fff' }} >
                        {/* 応対者 : <b>{selectedClient}</b> */}
                    </div>
                </Col>
            </Row>

            <Row gutter={24}>

                <Col span={24} style={{ divRightStyle }}>
                    <div style={{ padding: '0', height: '440px', backgroundColor: '#fff', with: '98%'}}>
                        <PhoneKeyboard />
                    </div>

                </Col>
            </Row>
        </div>

        </>
    );

}

export default ClientSection;