import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Tooltip,
  message,
  Pagination,
  Radio,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  Spin,
  Table
} from "antd";
import { SearchOutlined, SaveOutlined } from "@ant-design/icons";
import { UserContext } from "./UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { formatDateToYYYYMMDDHHMMSS, getShortDate } from "../../utils/Common";

import "../../utils/PhoneCall.css";
import { postCallData } from "../../services/instanceCallService";

const statusList = [
  { label: "", value: " " },
  { label: "不在", value: "0" },
  { label: "興味なし", value: "1" },
  { label: "即断り", value: "3" },
  { label: "着信拒否", value: "4" },
  { label: "再アクション", value: "5" },
  { label: "面談アサイン", value: "6" },
  { label: "イベントアサイン", value: "7" },
  { label: "電話番号不備", value: "8" },
  // { label: "接触前", value: "9" },
  { label: "追客中", value: "10" },
  { label: "サービス停止", value: "11" },
  { label: "サービス停止（メールOK）", value: "12" },
  { label: "前日確認OK", value: "13" },
  { label: "キャンセル", value: "14" },
  { label: "日程変更", value: "15" },
];

const CandidateLogsList = ({
  selectedKey,
  listData,
  handleRowSelect,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  handlePageChange,
  startIndex,
  endIndex,
  handleNext,
  handlePrevious,
}) => {
  const { setUser, author_id, setStatus, status } = useContext(UserContext);
  const [isWaiting, setWaiting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [isSearch, setIsSearch] = useState(false);
  const [searchData, setSearchData] = useState(listData);
  const [updatedStatus, setUpdatedStatus] = useState({});
  const textAreaRefs = useRef({});
  const curUserID = author_id ? author_id : 0;
  let totalRecord = 0;
  let paginatedData = [];

  useEffect(() => {
    setIsSearch(false);
  }, [listData]);

  if (isSearch) {
    //load data for search
    paginatedData = searchData.slice(startIndex, endIndex);
    totalRecord = searchData.length;

  } else {
    paginatedData = listData.slice(startIndex, endIndex);
    totalRecord = listData.length;
  }

  const changeStatus = async (value, candidateId) => {
    if (candidateId.candidateLogId === null) {
      // Update the status in local state
      setUpdatedStatus((prevStatus) => ({
        ...prevStatus,
        [candidateId.phone]: value,
      }));

      setStatus((prevStatus) => ({
        ...prevStatus,
        [candidateId.phone]: value,
      }));
      
      const updateModel = { author_id: curUserID, status: value == " " ? parseInt("") : parseInt(value) };
      // Update the logs
      UpdateLogs(updateModel, candidateId.phone);
      return;
    }

    // Update the status in local state
    setUpdatedStatus((prevStatus) => ({
      ...prevStatus,
      [candidateId.phone]: value,
    }));

    setStatus((prevStatus) => ({
      ...prevStatus,
      [candidateId.phone]: value,
    }));

    const updateModel = {
      author_id: curUserID,
      status: value == " " ? parseInt("") : parseInt(value),
      log_id: candidateId.candidateLogId,
    };
    // Update the logs
    UpdateLogs(updateModel, candidateId.phone);
  };

  const editComment = async (candidate) => {
    let canID = candidate.candidateLogId;

    let phoneNumber = candidate.phone;

    const commentInput =
      textAreaRefs.current[phoneNumber].resizableTextArea.textArea.value;

    if (!canID) {
      const updateParam = {
        author_id: curUserID,
        comments: commentInput,
      };
      // Update the comments
      setWaiting(true);
      try {
        await postCallData(`/call-log/${phoneNumber}/update`, updateParam);

        messageApi.success("Update comments success");
      } catch (error) {
        messageApi.error("Call comments had update failed ");
        setWaiting(false);
      } finally {
        setWaiting(false);
      }
      return;
    }

    const updateParam = {
      author_id: curUserID,
      comments: commentInput,
      log_id: canID,
    };

    // Update the comments
    setWaiting(true);
    try {
      await postCallData(`/call-log/${phoneNumber}/update`, updateParam);

      messageApi.success("Update comments success");
    } catch (error) {
      messageApi.error("Call comments had update failed ");
      setWaiting(false);
    } finally {
      setWaiting(false);
    }
  };

  const UpdateLogs = async (params, phoneNumber) => {
    setWaiting(true);
    try {
      await postCallData(`/call-log/${phoneNumber}/update`, params);
      messageApi.success("Call logs had updated success");
    } catch (error) {
      messageApi.error("Call logs had update failed ");
    } finally {
      setWaiting(false);
    }
  };

  const onFinish = async (values) => {
    const total_call = values.total_call ? parseInt(values.total_call) : "";
    const last_call = values.last_call
      ? getShortDate(values.last_call.toISOString())
      : "";
    const agreed_at = values.agreed_at
      ? getShortDate(values.agreed_at.toISOString())
      : "";
    const address = values.address ? values.address.toString() : "";
    const university = values.university ? values.university.toString() : "";
    const status = values.status ? values.status.toString() : "";
    let newData = listData.filter((item) => {
      if (
        (total_call === "" || item.total_call === total_call) &&
        (last_call === "" || item.last_call?.includes(last_call)) &&
        (agreed_at === "" || item.agreed_at?.includes(agreed_at)) &&
        (address === "" || item.address === address) &&
        (university === "" || item.university_name === university) &&
        (status === "" || item.status === status)
      ) {
        return item;
      }
    });
    paginatedData = newData.slice(startIndex, endIndex);
    setSearchData(newData);
    setIsSearch(true);
    setIsModalOpen(false);
    setCurrentPage(1);
  };

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <div className="container-fluid p-3">
      {contextHolder}
      <Button
        type="primary"
        icon={<SearchOutlined />}
        onClick={() => setIsModalOpen(true)}
      >
        詳細検索
      </Button>

      <div className="table-responsive-md mt-3">
        <Space>
          <Button type="link" onClick={handlePrevious}>
            前へ
          </Button>
          <Button type="link" onClick={handleNext}>
            次へ
          </Button>
        </Space>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">選択</th>
              <th scope="col">顧客名</th>
              <th scope="col">カウント</th>
              <th scope="col">最終架電日</th>
              {/* <th scope="col">ステータス</th>
              <th scope="col">メモ</th>
              <th scope="col">編集</th> */}
            </tr>
          </thead>
          <tbody>
            {paginatedData.length === 0 && (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  {isWaiting ? <Spin/> : "データがありません"}
                </td>
              </tr>
            )}
            {paginatedData.map((record, index) => {
              const isSelected = record.ID === selectedKey;
              return (
                <tr
                  key={record.ID}
                  onClick={() => handleRowSelect(record.ID, record)}
                  style={{
                    "--bs-table-bg":
                      record.count_recall > 0 ? "#7ff" : "transparent",
                    opacity: record.count_recall > 0 ? ".5" : ".9",
                  }}
                >
                  <td>
                    <Radio
                      checked={isSelected}
                      onChange={() => handleRowSelect(record.ID, record)}
                    />
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        setUser(record);
                      }}
                    >
                      {record.Name}
                    </a>
                  </td>
                  <td>{record.total_call}</td>
                  <td>
                    {record.last_call
                      ? formatDateToYYYYMMDDHHMMSS(record.last_call)
                      : ""}
                  </td>
                  {/* <td>
                    <Select
                      value={
                        updatedStatus[record.phone] ||
                        status[record.phone] ||
                        record.status
                      }
                      onChange={(value) => changeStatus(value, record)}
                      options={statusList}
                      loading={isWaiting}
                      style={{ width: "215px" }}
                    />
                  </td>
                  <td>
                    <Input.TextArea
                      ref={(el) => (textAreaRefs.current[record.phone] = el)}
                      defaultValue={
                        textAreaRefs.current[record.phone] || record.comments
                      }
                      rows={1}
                    />
                  </td>
                  <td>
                    <Tooltip title="Save note">
                      <Button
                        onClick={() => editComment(record)}
                        icon={<SaveOutlined />}
                        loading={isWaiting}
                      />
                    </Tooltip>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination 
          current={currentPage}
          pageSize={itemsPerPage}
          total={totalRecord}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{ textAlign: "center", marginTop: "16px" }}
        />
      </div>

      <Modal
        title="詳細検索"
        open={isModalOpen}
        onOk={form.submit}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="total_call" label="架電回数">
                <Input placeholder="架電回数" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="last_call" label="最終架電日">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="最終架電日"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="agreed_at" label="登録日">
                <DatePicker style={{ width: "100%" }} placeholder="登録日" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="address" label="エリア">
                <Input placeholder="エリア" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="university" label="大学">
                <Input placeholder="大学" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="status" label="架電ステータス">
                <Select
                  showSearch
                  placeholder="架電ステータス"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={statusList}
                  loading={isWaiting}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isWaiting}>
                  提出する
                </Button>
                &nbsp; &nbsp;
                <Button htmlType="button" onClick={handleReset}>
                  リセット
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CandidateLogsList;
