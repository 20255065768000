import React, { useState, useEffect, useCallback } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, Select, message, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Layout } from 'antd';
import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { getLocalStorageInfo, getLocalToken } from '../../utils/Common';
import { deleteDataUser, postDataUser, putDataUser } from '../../services/instanceUserService';

const globalConst = require('../../constants/global_const');

function UserManager() {
    const [cookies] = useCookies(['call_token']);
    const curToken = cookies.call_token ? cookies.call_token : getLocalToken();
    const [isLoading, setIsLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [userData, setUserData] = useState([]);
    const [isEditUser, setIsEditUser] = useState(false);
	const { Option } = Select;
    const [isWaiting, setIsWaiting] = useState(false);


    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const successMessage = (content) => {
        messageApi.open({
            type: 'success',
            content,
            className: 'custom-class',
            style: { marginTop: '20vh' },
        });
    };

    const showModal = (user = null) => {
        setModalTitle(user ? 'ユーザー情報を更新する' : '新しいユーザーを追加する');
        form.resetFields();
        if (user) {
            form.setFieldsValue({
				name: user.name,
				email: user.email,
				role: (user.is_admin === true) ? 'Admin' : 'User',
				userId: user.id,
                group_id: user.group_id,
			});
            setIsEditUser(true);
        } else {
            setIsEditUser(false);
            form.setFieldsValue({ userId: -1 });
        }
        setModalVisible(true);
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const handleFormSubmit = async (values) => {
        values.is_admin = values.role === 'Admin';
        values.group_id = getLocalStorageInfo('curUser').group_id || 1;
        if (values.userId === -1) {
            await addUser(values);
        } else {
            await editUser(values);
        }
        setModalVisible(false);
    };

    const fetchUserData = async () => {
        try {
            const result = await axios.get(
                `${globalConst.API_URL}/users`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${curToken}`,
                    },
                },
            );

            setUserData(result.data.data || []);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setIsLoading(false);
        }
    };

    const addUser = async (params) => {
        setIsWaiting(true);
        try {
            await postDataUser('/users', { ...params, password: 'dym@123456' });
            messageApi.success(`メールで新規ユーザーを追加 `);
        } catch (error) {
            messageApi.error('新しいユーザーを追加できませんでした');
            console.error('ユーザーの追加中にエラーが発生しました:', error);
            setIsWaiting(false);
        } finally {
            setIsWaiting(false);
            fetchUserData();
        }
    };

    const editUser = async (params) => {
        setIsWaiting(true);
        try {
            await putDataUser(`/users/${params.userId}`, params);
            successMessage('ユーザー情報を更新しました');
        } catch (error) {
            successMessage('ユーザー情報の更新に失敗しました');
            console.error('ユーザー情報の変更中にエラーが発生しました:', error);
            setIsWaiting(false);
        } finally {
            setIsWaiting(false);
            fetchUserData();
        }
    };

    const deleteUser = async (id) => {
        try {
            await deleteDataUser('/users/' + id);
            successMessage('ユーザーの削除に成功しました');
        } catch (error) {
            successMessage('ユーザーの削除に失敗しました');
            console.error('ユーザーの削除中にエラーが発生しました:', error);
        } finally {
            fetchUserData();
        }
    };

    const confirmDeleteUser = (id) => {
        Modal.confirm({
            title: `${id}を削除しますか?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => deleteUser(id),
        });
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const columns = [
        {
            title: '名前',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Eメール',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'ロール',
            dataIndex: 'is_admin',
            key: 'is_admin',
            render: (_, { is_admin }) => (
                <>
                  <Tag color={(is_admin === true) ? 'volcano' : 'geekblue'} key={is_admin}>
                      {(is_admin === true) ? 'Admin' : 'User'}
                  </Tag>
                </>
              ),
        },
        {
            title: '作成日',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'アクション',
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record)}>編集</Button>
                    <Button type="link" onClick={() => confirmDeleteUser(record.id)}>削除</Button>
                </Space>
            ),
        },
    ];
    

    return (
        <Layout>
            <HeaderLayout />
            <Layout>
                <NavBarLayout />
                <Layout style={{ padding: '0 24px 24px' }}>
                    {contextHolder}
                    <div style={{ minHeight: 780 }}>
                    {isLoading ? (
                            <div
                                style={{
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                backgroundColor: "transparent",
                                }}
                            >
                                <Spin size="large" />
                                <div className="ms-3 mt-2">読み込み中</div>{" "}
                            </div>
                        ) : (
                        <>
                            <h1>ユーザー管理</h1>
                            <Button type="primary" onClick={() => showModal()}>新しいユーザーを追加する</Button>
                            <Table
                                columns={columns}
                                pagination={{ position: ['bottomRight'] }}
                                dataSource={userData}
                                rowKey="id"
                            />
                            <Modal
                                title={modalTitle}
                                open={modalVisible}
                                onCancel={handleModalCancel}
                                footer={null}
                            >
                                <Form
                                    {...{
                                        labelCol: { span: 8 },
                                        wrapperCol: { span: 16 },
                                    }}
                                    form={form}
                                    onFinish={handleFormSubmit}
                                >
                                    <Form.Item
                                        name="name"
                                        label="名前"
                                        rules={[{ required: true }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="Eメール"
                                        rules={[{ required: true }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                        {
                                            isEditUser ? 
                                            <></> :
                                            <>
                                            <Form.Item
                                                name="example_password"
                                                label="パスワード"
                                                // rules={[{ required: true }]}
                                            >
                                                <label><b>dym@123456</b></label>
                                            </Form.Item>
                                            
                                            </>
                                        }
                                        
                                    <Form.Item
                                        name="role"
                                        label="ロール"
                                        rules={[{ required: true }]}
                                    >
                                        <Select placeholder="ロールを選択" allowClear>
                                            <Option value="Admin">Admin</Option>
                                            <Option value="User">User</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="userId" hidden>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{
                                            offset: 8,
                                            span: 16,
                                        }}
                                    >
                                        <Button type="primary" htmlType="submit" loading={isWaiting}>提出する</Button> &nbsp;&nbsp;
                                        <Button htmlType="button" onClick={handleModalCancel}>リセット</Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </>
                        )
                    }    
                    </div>
                    <FooterLayout />
                </Layout>
            </Layout>
        </Layout>
    );
}

export default UserManager;
