import React, { useState, useEffect, useMemo, useCallback,useContext } from "react";
import { Table, Tag, Space, Layout, message, Spin, Tooltip, DatePicker,Form,Select } from "antd";
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  CloudDownloadOutlined,
  ReloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { formatDateToYYYYMMDDHHMMSS, getShortDate } from "../../utils/Common";
import {
  downloadCallData,
  getCallData,
} from "../../services/instanceCallService";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { UserContext } from "../../components/call/UserContext";

const { Content } = Layout;

const CallLogs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [logData, setLogData] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState("");
  const [isDownloading, setIsDownloading] = useState("");
  const [currentAudioId, setCurrentAudioId] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("filter");
  const type = searchParams.get("type");
  const { author, setUser } = useContext(UserContext);
  const isAdmin = author?.is_admin;
  const is_enterprise = searchParams.get("is_enterprise");
  const [optTypeUpdateData, setUpdatedType] = useState([]);
  let urlAPI = '/call-log/list?';

  const typeData = [
    { id: "0", name: "to C" },
    { id: "1", name: "to B" },
  ];
  
  const optTypeData = useMemo(() => {
    return typeData?.map(({ id, name }) => ({ value: id, label: name })) || [];
  }, [typeData]);
  
  useEffect(() => {
    fetchData();
  }, [searchParams]);

  const fetchData = async () => {
    try {
      if (is_enterprise){
        urlAPI = `${urlAPI}&is_enterprise=${is_enterprise}`;
        // Get the user details from optSysUsers or optCurUsers
        const type = optTypeData.find((u) => u.value === is_enterprise) || [];

        if (type) {
          handleUpdateType(type.value, type.label);
        }
      }
      if (type === "date" && date) {
        setIsWaiting(true);
        const result = await getCallData(`${urlAPI}&date=${date}`);
        setLogData(result);
        setIsWaiting(false);
      } else {
        setIsWaiting(true);
        const result = await getCallData(`${urlAPI}`);
        setLogData(result);
        setIsWaiting(false);
      }

    } catch (error) {
      messageApi.success("Failed to delete project");
    } finally {
      setIsWaiting(false);
    }
  };

  const replayAudio = useCallback(() => {
    if (audio) {
      audio.currentTime = 0; // Reset the audio to the beginning
      audio.play();
      setIsPlaying("true");
    }
  }, [audio]);

  const togglePlayPause = useCallback(
    async (logId) => {
      setIsWaiting(true);
      if (currentAudioId && currentAudioId === logId) {
        if (audio) {
          if (audio.paused) {
            audio.play();
            setIsPlaying(logId);
          } else {
            audio.pause();
            setIsPlaying("");
          }
        }
        setIsWaiting(false);
        return;
      }
      if (audio) window.URL.revokeObjectURL(audio.src);

      try {
        const audioFile = await fetchAudio(logId);

        if (audioFile) {
          const url = window.URL.createObjectURL(audioFile);
          const newAudio = new Audio(url);
          newAudio.oncanplaythrough = () => {
            setAudio(newAudio);
            setIsPlaying(logId);
            setCurrentAudioId(logId);
            newAudio.play();
          };
          newAudio.onerror = (error) => {
            console.error("Error playing audio", error);
            setIsPlaying(false);
          };
          newAudio.onended = () => {
            setIsPlaying(false);
            window.URL.revokeObjectURL(url);
          };
        }
      } catch (error) {
        console.error("Error downloading or playing audio", error);
        setIsWaiting(false);
      } finally {
        setIsDownloading("");
        setIsWaiting(false);
      }
    },
    [audio, currentAudioId]
  );

  const fetchAudio = useCallback(async (logId) => {
    try {
      const audio = await downloadCallData(`/call-log/download/${logId}`);
      return audio;
    } catch (error) {
      console.error("Error fetching audio:", error);
      return null;
    }
  }, []);

  const downloadRecording = useCallback(
    async (logId) => {
      setIsDownloading(logId);
      const blob = await fetchAudio(logId);
      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${logId}.mp3`; // set the file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url); // free memory
      }
      setIsDownloading("");
    },
    [fetchAudio]
  );

  const handleDateChange = (date, dataString) => {
    if (date) {
      if(is_enterprise){
        setSearchParams({ filter: date?.format("YYYY-MM-DD"), type: "date", is_enterprise: is_enterprise });
      } else {
        setSearchParams({ filter: date?.format("YYYY-MM-DD"), type: "date" });
      }
    } else {
      searchParams.delete("filter");
      searchParams.delete("type");
      setSearchParams(searchParams);
    }
  };

  const handleChangeType = (is_enterprise, dataString) => {
    if (is_enterprise) {
      setSearchParams({ is_enterprise: is_enterprise});
      // Get the user details from optSysUsers or optCurUsers
      const type = optTypeData.find((u) => u.value === is_enterprise) || optTypeData[0];

      if (type) {
        handleUpdateType(type.value, type.label);
      }
    } else {
      searchParams.delete("is_enterprise");
      searchParams.delete("filter");
      searchParams.delete("type");
      setSearchParams(searchParams);
    }
  };

  const handleUpdateType = (is_enterprise, name) => {
    // Add or update the user in updatedUser state
    setUpdatedType((prevState) => ({
      ...prevState,
      [is_enterprise]: { value: is_enterprise, label: name },
    }));
  };

  const statusList = {
    0: "不在",
    1: "興味なし",
    3: "即断り",
    4: "着信拒否",
    5: "再アクション",
    6: "面談アサイン",
    7: "イベントアサイン",
    8: "電話番号不備",
    // 9: "接触前",
    10: "追客中",
    11: "サービス停止",
    12: "サービス停止（メールOK）",
    13: "前日確認OK",
    14: "キャンセル",
    15: "日程変更",
  };

  const columns = useMemo(
    () => [
      { title: "姓名", dataIndex: "name", key: "name", width: 170 },
      { title: "誕生日", dataIndex: "birthday", key: "birthday", width: 120 },
      {
        title: "応対者",
        dataIndex: "caller_name",
        key: "caller_name",
        width: 100,
        render: (caller_name) => <>{caller_name}</>,
      },
      {
        title: "Zoom 応対者",
        dataIndex: "sys_caller_name",
        key: "sys_caller_name",
        width: 120,
        render: (sys_caller_name) => <>{sys_caller_name}</>,
      },
      { title: "フリガナ", key: "furigana", dataIndex: "furigana", width: 170 },
      { title: "通話日", dataIndex: "call_date", key: "call_date", width: 120 },
      {
        title: "再生",
        dataIndex: "url_recording",
        key: "url_recording",
        render: (url_recording, record) =>
          record.play_back === 1 ? (
            <Space>
              <Tooltip
                title="Play/Pause Recording"
                color="cyan"
                key={`play_pause_${record.key}`}
              >
                <a onClick={() => togglePlayPause(record.url_recording)}>
                  {isPlaying === record.url_recording ? (
                    <PauseCircleOutlined
                      style={{ color: "hotpink", fontSize: "20px" }}
                    />
                  ) : (
                    <PlayCircleOutlined
                      style={{ color: "#52c41a", fontSize: "20px" }}
                    />
                  )}
                </a>
              </Tooltip>
              <Tooltip
                title="Replay Recording"
                color="cyan"
                key={`replay_${record.key}`}
              >
                <a onClick={() => replayAudio()}>
                  <ReloadOutlined
                    style={{
                      color: "#55f",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip
                title="Download Recording"
                color="cyan"
                key={`down_${record.key}`}
              >
                {isDownloading === record.url_recording ? (
                  <LoadingOutlined
                    style={{
                      color: "blue",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                  />
                ) : (
                  <a onClick={() => downloadRecording(record.url_recording)}>
                    <CloudDownloadOutlined
                      style={{
                        color: "hotpink",
                        fontSize: "20px",
                        marginLeft: "10px",
                      }}
                    />
                  </a>
                )}
              </Tooltip>
            </Space>
          ) : null,
        width: 120,
      },
      {
        title: "結果の呼び出し",
        dataIndex: "result_call",
        key: "result_call",
        width: 130,
      },
      {
        title: "ステータス",
        dataIndex: "status",
        key: "status",
        render: (status) => (
          <Tag color={status ? "volcano" : "geekblue"}>
            {statusList[status] || ""}
          </Tag>
        ),
        width: 200,
      },
      {
        title: "性別",
        dataIndex: "gender",
        key: "gender",
        width: 120,
        render: (gender) => <>{gender}</>,
      },
      { title: "電話番号", dataIndex: "phone", key: "phone", width: 120 },
      { title: "住所", dataIndex: "address", key: "address", width: 120 },
      {
        title: "郵便番号",
        dataIndex: "postal_code",
        key: "postal_code",
        width: 120,
      },
      ...(is_enterprise
        ? [] // Empty array when is_enterprise is true, no extra columns
        : [
            {
              title: "大学名",
              dataIndex: "university_name",
              key: "university_name",
              width: 120,
            },
            {
              title: "学校種類",
              dataIndex: "school_type",
              key: "school_type",
              width: 120,
            },
            {
              title: "学部学科",
              dataIndex: "department",
              key: "department",
              width: 120,
            },
            {
              title: "文理",
              dataIndex: "educational_stream",
              key: "educational_stream",
              width: 130,
            },
            {
              title: "卒業年",
              dataIndex: "graduation_year",
              key: "graduation_year",
              width: 120,
            },
            {
              title: "就職希望年度",
              dataIndex: "work_year",
              key: "work_year",
              width: 120,
            },
            {
              title: "志望業界",
              dataIndex: "desired_industry",
              key: "desired_industry",
              width: 120,
            },
          ]),
      {
        title: "最終架電日",
        dataIndex: "last_call",
        key: "last_call",
        width: 145,
      },
    ],
    [isPlaying, isDownloading, togglePlayPause, replayAudio, downloadRecording, is_enterprise]
  );
  
  const logsData = useMemo(() => {
    if (logData.data && Object.keys(logData.data).length > 0) {
      let logKey = 0;
      return logData.data.map((item) => ({
        key: logKey++,
        candidate: item.candidate_id,
        name: item.candidate_name,
        furigana: item.furigana,
        email: item.email,
        phone: item.candidate_phone,
        address: item.address,
        birthday: item.birthday ? getShortDate(item.birthday) : "",
        gender: item.gender,
        postal_code: item.postal_code,
        callee_number: item.callee_number,
        school_type: item.school_type,
        university_name: item.university_name,
        educational_stream: item.educational_stream,
        department: item.department,
        graduation_year: item.graduation_year,
        work_year: item.work_year,
        desired_industry: item.desired_industry,
        total_call: item.total_call,
        last_call: formatDateToYYYYMMDDHHMMSS(item.last_call),
        result_call: item.result,
        author_id: item.author_id,
        receipt_id: item.receipt_id,
        status: item.status,
        call_date: formatDateToYYYYMMDDHHMMSS(item.date_time),
        play_back: item.play_back,
        caller_name: item.caller_name,
        sys_caller_name: item.sys_caller_name,
        url_recording: item?.call_record_id ?? "",
      }));
    }
    return [];
  }, [logData]);

  return (
    <Layout>
      <HeaderLayout />
      {contextHolder}
      <Layout>
        <NavBarLayout />
        <Content style={{ padding: "10px" }}>
          <div style={{ minHeight: 780 }}>
            {isLoading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spin size="large" /> {/* Show loading spinner */}
                <p>Loading...</p> {/* Optional: Show a loading message */}
              </div>
            ) : (
              <>
               <Space>
                <h1>{is_enterprise ? ( <span>to B</span> ) : (<span>to C</span>) } コールログ</h1>
                {/* <Select
                    showSearch
                    disabled={false}
                    placeholder="ロールを選択してください"
                    options={optTypeData}
                    onChange={(value) => handleChangeType(value)}
                    value={optTypeUpdateData[is_enterprise]?.value || []}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                /> */}
                <DatePicker
                    style={{ width: "100%" }}
                    onChange={handleDateChange}
                    format="YYYY-MM-DD"
                    placeholder="日にちを選択"
                    value={
                      type === "date" && date ? dayjs(date, "YYYY-MM-DD") : null
                    }
                />
                </Space>
                <Table
                  columns={columns}
                  dataSource={logsData}
                  rowKey="key"
                  pagination={false}
                  scroll={{ y: 690, x: 580 }}
                  loading={isWaiting}
                />
              </>
            )}
          </div>
          <FooterLayout />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CallLogs;
