import React, { useState, useEffect, useContext } from 'react';
import styles from './Login.module.css';
import axios from 'axios';
import { setUserSession, Loading, setLocalStorageInfo, getUser } from '../../utils/Common';
import { useNavigate } from 'react-router';
import { Form, Input, Button, Checkbox, Image } from 'antd';
import { useCookies } from 'react-cookie';
import globalConst from '../../constants/global_const';
import { UserContext } from '../call/UserContext';

const Login = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['call_token']);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const apiHost = globalConst.API_URL + '/auth';
  const {setAuthorId} = useContext(UserContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className={styles.mainSection}>
        <Loading />
      </div>
    );
  }

  const onFinish = async (values) => {
    try {
      setError(null);
      setIsLoading(true);

      const response = await axios.post(apiHost + '/login', { email: values.username, password: values.password });
      const { access_token, user } = response.data.data;

      let expires = new Date();
      expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days
      setCookie('call_token', access_token, { path: '/', secure: true, sameSite: 'None', expires });

      setUserSession(access_token, user);
      localStorage.setItem('call_token', access_token);
      let curUser = getUser();
      setLocalStorageInfo('curUser', JSON.stringify(curUser));
      setAuthorId(curUser.id);

      navigate('/homeAdmin/call-meeting');
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 'fails') {
        setError(error.response.data.message);
      } else {
        setError("問題が発生しています。後ほどお試しください。");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.mainSection}>
      <div className={styles.login_box}>
      <div 
          className={styles.illustration_wrapper}
          style={{
            backgroundImage: "url('/uploads/login_bg.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '800px',
            height: '700px' 
          }}
        />
        
        <Form
          className={styles.form_div}
          form={form}
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className={styles.form_title}><b>コールセンターシステムへようこそ</b></p>
          <p><b>ダッシュボードにログイン</b></p>
          {error && <p className={styles.error_message}>{error}</p>}
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'メールアドレスを入力してください。' }]}
          >
            <Input placeholder="Eメール" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'パスワードを入力してください。' }]}
          >
            <Input.Password placeholder="パスワード" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.login_form_button}>
            ログイン
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
