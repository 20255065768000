import React from 'react'
	
import {Navigate, Outlet} from 'react-router-dom';
import { removeUserSession, deleteCookie, getLocalToken, getLocalStorageInfo } from '../utils/Common';


//console.log('Session info ', sessionStorage.getItem('user'));
const useAuth=()=>{
	//const user=sessionStorage.getItem('user');
	const userInfo = getLocalStorageInfo('curUser');
	//console.log('Check user login ', typeof(user));
	if((userInfo && userInfo !== 'null') | userInfo !== null){
	return true
	} else {
	return false
	}
}


const  ProtectedRoutes=(props:any) =>{


	const auth=useAuth()
	return auth?<Outlet/>: <Navigate to="/login"/>
}


export default ProtectedRoutes;