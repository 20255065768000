import React from "react";
import { Layout, Breadcrumb } from 'antd';
import { Routes , Route} from 'react-router-dom';
import UserManager from "../components/users/UserManager";
import UserGroup from "../components/users/UserGroup";
// import Category from "../components/category/Category";
// import Article from "../components/article/Article";
// import ProductCategory from "../components/product_category/ProductCategory";
// import Product from "../components/products/Products";
// import Ads from "../components/ads/Ads";

const { Content } = Layout;

function MainSection(props){

  return (
    <div>
    <Breadcrumb style={{ margin: '16px 0' }}
      items={[{title: "Home"}, {title: "List"}, {title: "App"}]}
    >
      
    </Breadcrumb>
    <Content className="site-layout-background"
      style={{
        padding: 5,
        margin: 0,
        minHeight: 580,
        
      }}
    >

    <Routes >
      <Route path="/homeAdmin/user-manager" component={UserManager} />
      <Route path="/homeAdmin/user-group" component={UserGroup} />
      {/* <Route path="/homeAdmin/content-category" component={Category} />
      <Route path="/homeAdmin/content-article" component={Article} />
      <Route path="/homeAdmin/product-category" component={ProductCategory} />
      <Route path="/homeAdmin/product" component={Product} />
      <Route path="/homeAdmin/ads" component={Ads} /> */}
    </Routes > 

    {props.children}  
    
  </Content>
  </div>
  );

};
export default MainSection;