import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { Table, Tag, Space, Select, message, Input, Tooltip } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { UserContext } from './UserContext';
import { getFullDateTime, getLabelByValue, getLocalStorageInfo, getLocalToken, secondsToHHMMSS } from '../../utils/Common';

const globalConst = require('../../constants/global_const');


const reproductionItems = [
    { label: '不在', value: '0' },
    { label: '興味なし', value: '1' },
    { label: '即断り', value: '3' },
    { label: '着信拒否', value: '4' },
    { label: '再アクション', value: '5' },
    { label: '面談アサイン', value: '6' },
    { label: 'イベントアサイン', value: '7' },
    { label: '電話番号不備', value: '8' },
    // { label: '接触前', value: '9' },
    { label: '追客中', value: '10' },
    { label: 'サービス停止', value: '11' },
    { label: 'サービス停止（メールOK）', value: '12' },
    { label: '前日確認OK', value: '13' },
    { label: 'キャンセル', value: '14' },
    { label: '日程変更', value: '15' },

];
  

const TwilioCallHistory = (user2Child) => {
  let callessNum = '+0000000000';
  const { userInfo } = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [cookies] = useCookies(['call_token']);
  const curToken = cookies.call_token ? cookies.call_token : getLocalToken();

  const [selectedRowKeys, setSelectedRowKeys] = useState(['Empty']);
  const [selectedRows, setSelectedRows] = useState([]);
  const userLogin = getLocalStorageInfo('curUser');
  const curUserID = (userLogin) ? userLogin.id : 0;
  const curUserName = (userLogin) ? userLogin?.name : 'Empty';
  const { TextArea } = Input;
  const [inputValue, setInputValue] = useState('');
  const [reproductionValues, setReproductionValues] = useState({});
  
  const success = (contents) => {
    messageApi.open({
        type: 'success',
        content: contents,
        className: 'custom-class',
        style: { marginTop: '20vh' },
    });
  };

  const handleCreateChildTask = (receptionist) => {
    success('Clicked change user');
    console.log('record', receptionist);
  };
    
  let userList = user2Child.user2Child;
  const optUsers = useMemo(() => {
    if (userList && Object.keys(userList).length > 0) {
      return userList.map((item) => ({
        label: item.display_name,
        value: item.id
      }));
    }
  }, [userList]);


  if(userInfo){
    callessNum = userInfo.callee_number;
  }

  const [userData, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [callessNum]);

  useEffect(() => {

  }, [selectedRowKeys, selectedRows]);


  const fetchData = async () => {
    try {
      const result = await axios.get(`${globalConst.CALL_SERVICE_URL}/call-log/${callessNum}/detail`, {
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${curToken}`,
        },
      });
      setData(result.data);

    } catch (error) {
      // setIsLoading(false);
    } finally {
      // setIsLoading(false);
      setSelectedRowKeys(['Empty']);

    }


  };

  const updateCallLog = useCallback(async (chgStatus) => {
    console.log('Update call log ', chgStatus);
  }, []);

  const UpdateLogs = async (params, callId) => {
    
    const results = await axios.post(globalConst.CALL_SERVICE_URL + '/call-log/' + callId + '/update', params, {
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${curToken}`,
        },
    });
    if (results.data.success) {
        success('Call logs had updated success');
        //window.location.reload();
    } else {
        success('Call logs had update failed ');
    }
    
};


  const changeUser = ({ value }, receptionist) => {
    let updateModel = {};
    // console.log('select id', selectedRowKeys);
    if(selectedRowKeys[0] && selectedRowKeys[0].length > 0) {
      updateModel.author_id = curUserID;
      updateModel.receipt_id = receptionist.value;
    } else {
      success('Please select rows for update, thank you!' );
      return;
    }
    //handleCreateChildTask(receptionist);
    //
    console.log('Model update ', updateModel);
    UpdateLogs(updateModel, selectedRowKeys[0]);
  }

  const changeStatus = ({ value }, reproduction) => {
    let updateModel = {};
    // console.log('Select key ', selectedRowKeys[0], selectedRowKeys[0].length); return false;
    if(selectedRowKeys[0] !== 'Empty') {
      updateModel.author_id = curUserID;
      updateModel.status = parseInt(value);
      setReproductionValues(prev => ({ ...prev, [selectedRowKeys[0]]: value }));
      
    } else {
      success('Please select rows for update, thank you!');
      return;
    }
    //handleCreateChildTask(receptionist);
    console.log('Model update ', updateModel);
    UpdateLogs(updateModel, selectedRowKeys[0]);
  }


  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const editMemo = async() => {

    if(selectedRowKeys[0] === 'Empty') {
      success('Please select rows for update, thank you!');
      return;
    }

    const callID = selectedRowKeys[0];
    console.log('edit with row id ', callID);
    if(inputValue.length === 0) {
      success('Please fill note field, thank you!');
      return;
    }

    const updateParam = {
        "author_id": curUserID,
        "comments": inputValue,
    }

    console.log('Input Memo Param:', updateParam, callID);
    //return false;
    const results = await axios.post(globalConst.CALL_SERVICE_URL + '/call-log/' + callID + '/update', updateParam, {
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${curToken}`,
        },
    });
    if (results.data.success) {
        success('Update status success' );
        //window.location.reload();
    } else {
        success('Update status had failed ' );
    }

      
  }

  //console.log('data on call api ', userData);
  const columns = [
    { title: 'タイプ', dataIndex: 'type', key: 'type', width: 80 },
    { title: '日付時刻', dataIndex: 'date_time', key: 'date_time', width: 120 },
    {
      title: '著者',
      dataIndex: 'author',
      key: 'author',
      width: 90,
      render: (author) => {
        return (
          // <label>{getLabelByValue(optUsers, author)}</label>
          <label>{author}</label>
        )
      }
    },
    {
      title: '通話時間',
      key: 'call_duration',
      dataIndex: 'call_duration',
      width: 150,
    },
    {
      title: '応対者',
      dataIndex: 'receptionist',
      key: 'receptionist',
      width: 170,
      render: () => {
        return <>{curUserName}</>
      }

    },
    { title: 'ステータス', dataIndex: 'reproduction', key: 'reproduction', width: 170,
        render: (reproduction, record) => {
          return (
            <span>
            {/* {getLabelByValue(reproductionItems, reproduction)} */}
            <Select
              showSearch
              placeholder="受付リスト"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              style={{
                width: 150,
              }}
              // onChange={changeStatus}
              onChange={(value) => changeStatus({ value }, record)}
              value={reproductionValues[record.key] || reproduction}
              options={reproductionItems} 
            />
            </span>
          );
        },  
    },
    {
      title: '再生',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <></>
      ),
      width: 130,
    },
    {
      title: 'メモ', dataIndex: 'memo', key: 'memo', width: 250,
      render: (memo, record) => {
        return(
          <TextArea placeholder="メモを入力してください" key={record.key} value={memo} onChange={handleInputChange} autoSize/>
        )
      }
    },
    { title: '編集', dataIndex: 'callEdit', key: 'callEdit', width: 90,
        render: (callEdit) => {
            return (callEdit === 0) ? <CloseOutlined /> : <Tooltip title="Save note" color='cyan' key='cyan'> <a onClick={editMemo}><SaveOutlined /></a></Tooltip>
        },
        
     },
  ];

  //console.log('his data ', userData.data);

  const hisData = useMemo(() =>{
    if (userData.data && Object.keys(userData.data).length > 0) {
      return userData.data.map((item) => ({
        key: item.id,
        type: 'call',
        date_time: getFullDateTime(item.date_time),
        author: item.author_id,
        call_duration: secondsToHHMMSS(item.talk_time),
        receptionist: item.receipt_id,
        status: item.status,
        reproduction: item.status,
        memo: item.comments,
        callEdit: 1,
      }));
    }
  });


  return (
    <div style={{ padding: '0', height: '330px', backgroundColor: '#fff' }}>
      <label>ログID選択 : <b>{selectedRowKeys}</b></label>
      {contextHolder}
        <Table
          columns={columns}
          dataSource={hisData}
          rowKey="key"
          pagination={false}
          scroll={{ y: 370 }}
          rowSelection={rowSelection}
        />
    </div>
  );
};

export default TwilioCallHistory;
