import React, { useContext, useEffect, useState, useMemo } from "react";
import { Table, Tag, Space, Select, message, Tooltip } from "antd";
import {
  CloudDownloadOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  ReloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { UserContext } from "./UserContext";
import {
  formatDateToYYYYMMDDHHMMSS,
  getLabelByValue,
  secondsToHHMMSS,
} from "../../utils/Common";
import { downloadCallData, getCallData, postCallData } from "../../services/instanceCallService";
import { getDataUser } from "../../services/instanceUserService";

const CallHistory = ({}) => {
  let calleeNum = null;
  let isFirstLoading = false;
  const { userInfo, author, setUser } = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const curUserID = author?.id;
  const curUserName = author?.name;
  const isAdmin = author?.is_admin;
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [userData, setData] = useState([]);
  const [isDownloading, setIsDownloading] = useState("");
  const [currentAudioId, setCurrentAudioId] = useState(null);
  const [updatedUser, setUpdatedUser] = useState([]);
  const [sysUserData, setSysUserData] = useState([]);

  const replayAudio = () => {
    if (audio) {
      audio.currentTime = 0; // Reset the audio to the beginning
      audio.play();
      setIsPlaying("true");
    }
  };

  const togglePlayPause = async (logId) => {
    setIsWaiting(true);
    if (currentAudioId && currentAudioId === logId) {
      if (audio) {
        if (audio.paused) {
          audio.play();
          setIsPlaying(logId);
        } else {
          audio.pause();
          setIsPlaying("");
        }
      }
      setIsWaiting(false);
      return;
    }
    if (audio) window.URL.revokeObjectURL(audio.src);

    try {
      const audioFile = await fetchAudio(logId);

      if (audioFile) {
        const url = window.URL.createObjectURL(audioFile);
        const newAudio = new Audio(url);
        newAudio.oncanplaythrough = () => {
          setAudio(newAudio);
          setIsPlaying(logId);
          setCurrentAudioId(logId);
          newAudio.play();
        };
        newAudio.onerror = (error) => {
          console.error("Error playing audio", error);
          setIsPlaying(false);
        };
        newAudio.onended = () => {
          setIsPlaying(false);
          window.URL.revokeObjectURL(url);
        };
      }
    } catch (error) {
      console.error("Error downloading or playing audio", error);
      setIsWaiting(false);
    } finally {
      setIsDownloading("");
      setIsWaiting(false);
    }
  };

  const fetchAudio = async (logId) => {
    try {
      const audio = await downloadCallData(`/call-log/download/${logId}`);

      return audio;
    } catch (error) {
      console.error("Error fetching audio:", error);
      return null;
    }
  };

  const success = (contents) => {
    messageApi.open({
      type: "success",
      content: contents,
      className: "custom-class",
      style: { marginTop: "20vh" },
    });
  };

  const fetchSysUser = async () => {
    if (isAdmin) {
      try {
        const result = await getDataUser("/users");
        setSysUserData(result.data.data || []);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        //setIsLoading(false);
      }
    } else {
      setSysUserData([]);
    }
  };

  if (userInfo) {
    calleeNum = userInfo.callee_number;
  }

  useEffect(() => {
    fetchSysUser();
    isFirstLoading = true;
  }, []);

  useEffect(() => {
    if(!isFirstLoading && calleeNum != 'undefined' && calleeNum != null){
      fetchData();
    } else {
      isFirstLoading = false;
      setData([]);
    }
  }, [calleeNum]);

  const optSysUsers = useMemo(() => {
    if (sysUserData && Object.keys(sysUserData).length > 0) {
      return sysUserData.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
  }, [sysUserData]);

  const optCurUsers = [
    {
      label: curUserName,
      value: curUserID,
    },
  ];

  const fetchData = async () => {
    if (calleeNum === null) {
      setData([]);
      return false;
    }

    setIsWaiting(true);
    try {
      const result = await getCallData(`/call-log/${calleeNum}/detail`);
      setData(result);
      setIsWaiting(false);
    } catch (error) {
      setIsWaiting(false);
    } finally {
      setSelectedRowKeys([]);
      setIsWaiting(false);
    }
  };

  const UpdateLogs = async (params, callId) => {
    setIsWaiting(true);
    try {
      params.log_id = callId;
      params.by_user = true;
      await postCallData(`/call-log/${callId}/update`, params);

      success("Call logs had updated success");
    } catch (error) {
      messageApi.error("Error updating call logs");
      setIsWaiting(false);
    } finally {
      setIsWaiting(false);
    }
  };

  const changeUser = (value, record) => {
    UpdateLogs({ author_id: value }, record.key);
    setUpdatedUser({ ...updatedUser, [record.key]: value });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const downloadRecording = async (logId) => {
    setIsDownloading(logId);
    const blob = await fetchAudio(logId);
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${logId}.mp3`; // set the file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url); // free memory
    }
    setIsDownloading("");
  };

  const columns = [
    { title: "タイプ", dataIndex: "type", key: "type", width: 80 },
    { title: "日付時刻", dataIndex: "date_time", key: "date_time", width: 110 },

    {
      title: "通話時間",
      key: "call_duration",
      dataIndex: "call_duration",
      width: 110,
    },

    {
      title: "応対者",
      dataIndex: "author",
      key: "author",
      width: 180,
      render: (author, record, _) => {
        return isAdmin ? (
          <span>
            <Select
              showSearch
              placeholder="応対者"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: 150 }}
              onChange={(value) => changeUser(value, record)}
              value={updatedUser[record.key] || record.author}
              options={optSysUsers}
              loading={isWaiting}
            />
          </span>
        ) : author !== null ? (
          <>
            <Tag color="volcano">{getLabelByValue(optCurUsers, author)}</Tag>
          </>
        ) : (
          <>
            <span>
              <Select
                showSearch
                placeholder="応対者"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: 150 }}
                onChange={(value) => changeUser(value, record)}
                options={optCurUsers}
                loading={isWaiting}
              />
            </span>
          </>
        );
      },
    },

    {
      title: "再生",
      dataIndex: "url_recording",
      key: "url_recording",
      render: (_, record) => {
        return record.play_back === 1 ? (
          <>
            <Space>
              <Tooltip
                title="Play/Pause Recording"
                color="cyan"
                key={`play_pause_${record.key}`}
              >
                <a onClick={() => togglePlayPause(record.key)}>
                  {isPlaying == record.key ? (
                    <PauseCircleOutlined
                      style={{ color: "hotpink", fontSize: "20px" }}
                    />
                  ) : (
                    <PlayCircleOutlined
                      style={{ color: "#52c41a", fontSize: "20px" }}
                    />
                  )}
                </a>
              </Tooltip>
              <Tooltip
                title="Replay Recording"
                color="cyan"
                key={`replay_${record.key}`}
              >
                <a onClick={() => replayAudio()}>
                  <ReloadOutlined
                    style={{
                      color: "#55f",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </a>
              </Tooltip>
              <Tooltip
                title="Download Recording"
                color="cyan"
                key={`down_${record.key}`}
              >
                {isDownloading == record.key ? (
                  <LoadingOutlined
                    style={{
                      color: "blue",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                  />
                ) : (
                  <a onClick={() => downloadRecording(record.key)}>
                    <CloudDownloadOutlined
                      style={{
                        color: "hotpink",
                        fontSize: "20px",
                        marginLeft: "10px",
                      }}
                    />
                  </a>
                )}
              </Tooltip>
            </Space>
          </>
        ) : (
          <></>
        );
      },
      width: 130,
    },
  ];

  const hisData = useMemo(() => {
    if (userData.data && Object.keys(userData.data).length > 0) {
      return userData.data.map((item) => ({
        key: item?.id, // Use 'no-id' if item.id is undefined
        type: "call",
        date_time: formatDateToYYYYMMDDHHMMSS(item?.date_time) ?? "",
        author: item.author_id,
        call_duration: item?.talk_time
          ? secondsToHHMMSS(item.talk_time)
          : "00:00",
        receptionist: item?.receipt_id ?? "unknown",
        status: item.status,
        reproduction: item.status,
        memo: item?.comments ?? "",
        play_back: item?.play_back ?? 0,
        url_recording: item?.url_recording ?? "",
        callEdit: 1,
      }));
    }
  });

  return (
    <div style={{ padding: "0", height: "30px", backgroundColor: "#fff" }}>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={hisData}
        rowKey="key"
        pagination={false}
        scroll={{ y: 370 }}
        rowSelection={rowSelection}
        loading={isWaiting}
      />
    </div>
  );
};

export default CallHistory;
