import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { message, Table, Layout, Select, Space, Col, Row, Button } from 'antd';
import { useCookies } from 'react-cookie';
import { Loading, getToken } from '../../utils/Common';
import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import globalConst from '../../constants/global_const';

const { Content } = Layout;

const GSSList = () => {
    const [cookies] = useCookies(['call_token']);
    const curToken = cookies.call_token ? cookies.call_token : getToken();
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [isWaitting, setWaitting] = useState(false);
    const [projectData, setProjectData] = useState([]);
    const [selectedWorkgroup, setSelectedWorkgroup] = useState(null);
    const [wgData, setWgData] = useState([]);

    const projectId = selectedWorkgroup ? selectedWorkgroup.value : 1;

    const fetchUserData = useCallback(async () => {
        try {
            const result = await axios.get(`${globalConst.REPORT_URL}/${projectId}/candidates`, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },
            });
            setUserData(result.data.data || []);
        } catch (error) {
            messageApi.error('データの取得に失敗しました。まずプロジェクトを選択する必要があります');
        } finally {
            setIsLoading(false);
        }
    }, [curToken, messageApi]);

    const fetchWgData = useCallback(async () => {
        try {
            const result = await axios.get(
                `${globalConst.MANAGEMENT_URL}/work_groups`,
                { 
                    credentials: 'include',
                    mode: 'cors',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${curToken}`,
                    },
    
                }
            );
            const data = result.data.data.map(record => ({
                key: record.id,
                id: record.id,
                name: record.name,
            }));
            setWgData(data);
        } catch (error) {
            console.log('Call API error', error);
        }
    }, []);

    useEffect(() => {
        fetchWgData();
    }, [fetchWgData]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const optData = useMemo(() => {
        return wgData?.map(({ id, name }) => ({ value: id, label: name })) || [];
    }, [wgData]);



    const handleChangeWorkgroup = async(value) => {
        console.log('Workgroup select ', value);
        setSelectedWorkgroup(value);
        //fetchProjectData(value);
        let proData = [];
        try {
            setWaitting(true);
            const { data } = await axios.get(`${globalConst.MANAGEMENT_URL}/work_groups?wrk_groups=${value}`, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },
            });

            // console.log('Data return ', data.data[0].projects);
            setProjectData(data.data[0].projects);
            if(data.data[0].projects) {
                //setProjectData(data.data[0].projects);
                data.data[0].projects.forEach(element => {
                    proData.push({value: element.id, label: element.name}) 
                });
            }
            // console.log('Data project ', proData);
            setProjectData(proData);
            setWaitting(false);
        } catch (error) {
            //console.error('Call API error ', error);
            setProjectData([]);
            setWaitting(false);
        } finally {
            setWaitting(false);
            //console.log('Data return ', data);
        }

    };

    const handleChangeProject = async(value) => {
        console.log('project select ', value);
        setUserData([]);
        try {
            setWaitting(true);
            const result = await axios.get(`${globalConst.REPORT_URL}/${value}/candidates`, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },
            });
            setUserData(result.data.data || []);
            setWaitting(false);
        } catch (error) {
            messageApi.error('データの取得に失敗しました。まずプロジェクトを選択する必要があります.');
            setWaitting(false);
        } finally {
            setWaitting(false);
        }
    }

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id', width: 80 },
        { title: '候補者名', dataIndex: 'name', key: 'name', render: text => <a>{text}</a>, width: 150 },
        { title: 'Furigana', dataIndex: 'furigana', key: 'furigana', width: 150 },
        { title: '誕生日', dataIndex: 'birthday', key: 'birthday', width: 120 },
        { title: '性別', dataIndex: 'gender', key: 'gender', width: 120,
            render: (gender) => {
                  return (
                    <>{gender}</>
                  );
            },  
        },
        { title: 'Eメール', dataIndex: 'email', key: 'email', width: 200 },
        { title: '番号', dataIndex: 'postal_code', key: 'postal_code', width: 120 },
        { title: '電話', dataIndex: 'phone', key: 'phone', width: 120 },
        { title: '住所', dataIndex: 'address', key: 'address', width: 250 },
        { title: '学校の種類', dataIndex: 'school_type', key: 'school_type', width: 120 },
        { title: '大学名', dataIndex: 'university_name', key: 'university_name', width: 150 },
        { title: '教育ストリーム', dataIndex: 'educational_stream', key: 'educational_stream', width: 150 },
        { title: '部門', dataIndex: 'department', key: 'department', width: 150 },
        { title: '卒業年', dataIndex: 'graduation_year', key: 'graduation_year', width: 120 },
        { title: '勤務年数', dataIndex: 'work_year', key: 'work_year', width: 120 },
        { title: '希望業界', dataIndex: 'desired_industry', key: 'desired_industry', width: 150 },
        { title: '合意した', dataIndex: 'agreed_at', key: 'agreed_at', width: 120 },
        { title: '状態', dataIndex: 'status', key: 'status', width: 100 },
    ];

    const listData = useMemo(() => {
        return userData.map(item => ({
            key: item.id,
            id: item.id,
            name: item.name,
            furigana: item.furigana,
            birthday: item.birthday,
            gender: item.gender,
            email: item.email,
            postal_code: item.postal_code,
            phone: item.phone,
            address: item.address,
            school_type: item.school_type,
            university_name: item.university_name,
            educational_stream: item.educational_stream,
            department: item.department,
            graduation_year: item.graduation_year,
            work_year: item.work_year,
            desired_industry: item.desired_industry,
            agreed_at: item.agreed_at,
            status: item.status,
        }));
    }, [userData]);

    const getData = () => {
        return [];
    }

    if (isLoading) {
        return (
            <Layout>
                <HeaderLayout />
                <Layout>
                    <NavBarLayout />
                    <Content style={{ padding: '10px', height: '900px' }}>
                        <Loading />
                    </Content>
                    <FooterLayout />
                </Layout>
            </Layout>
        );
    }

    return (
        <Layout>
            <HeaderLayout />
            {contextHolder}
            <Layout>
                <NavBarLayout />
                <Content style={{ padding: '10px', height: '900px' }}>
                    <div style={{ minHeight: 760 }}>
                        <h1>GSSからのマスターデータ </h1>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Row>
                                <Col span={24}>
                                    <label><b>ワークグループを選択 </b> </label>
                                    <Select
                                        showSearch
                                        placeholder="ワークグループを選択 "
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        allowClear
                                        options={optData}
                                        onChange={handleChangeWorkgroup}
                                        loading={isWaitting}
                                    />
                                    &nbsp;&nbsp;
                                    <label><b>プロジェクトを選択 </b> </label>
                                    <Select
                                        showSearch
                                        placeholder="プロジェクトを選択 "
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        allowClear
                                        options={projectData}
                                        onChange={handleChangeProject}
                                        loading={isWaitting}
                                    />
                                </Col>
                            </Row>
                            
                        </Space>
                        <Table columns={columns} dataSource={listData} scroll={{ y: 590 }} loading={isWaitting} />
                    </div>
                    <FooterLayout />
                </Content>
            </Layout>
        </Layout>
    );
};

export default GSSList;
