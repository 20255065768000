import React, { createContext, useState } from "react";
import { getLocalStorageInfo } from "../../utils/Common";

// Tạo UserContext
export const UserContext = createContext();

// Tạo Provider component
export const UserProvider = ({ children }) => {
  const [userInfo, setUser] = useState(null);
  const [authorId, setAuthorId] = useState(null);
  const [status, setStatus] = useState("");
  const userLocal = getLocalStorageInfo("curUser");
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : null;
  const author_id = userLocal?.id;
  const author = userLocal ? userLocal : null;

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUser,
        author_id,
        author,
        token,
        setAuthorId,
        authorId,
        setStatus,
        status
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
