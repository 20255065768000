import React, { useState, useEffect } from "react";
import { Loading } from "../../utils/Common";
import { Tabs, Col, Row, Layout, Spin } from "antd";

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import WorkgroupData from "./WorkgroupData";
import WgProjectData from "./WgProjectData";
import { getManagementData } from "../../services/instanceManagementService";
import { getDataUser } from "../../services/instanceUserService";

function Projects() {
  const [isLoading, setIsLoading] = useState(false);
  const [workGroup, setWorkGroup] = useState([]);
  const [selectedWorkGroup, setSelectedWorkGroup] = useState([]);
  const [listUser, setListUser] = useState([]);

  useEffect(() => {
    const fetchWorkGroup = async () => {
      setIsLoading(true);
      try {
        const { data } = await getManagementData("/work_groups");
        setWorkGroup(data);
      } catch (error) {
        console.log("Error fetching work groups:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchListUser = async () => {
      setIsLoading(true);
      try {
        const { data } = await getDataUser("/users");
        setListUser(data.data);
      } catch (error) {
        console.log("Error fetching work groups:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    fetchWorkGroup();
    fetchListUser();
  }, []);

  const items = [
    {
      key: "tabWorkgroup",
      label: "プロジェクト管理 ",
      children: (
        <Row key={"gw1"} gutter={[8, 16]}>
          <Col span={4}>
            <WorkgroupData
              workGroup={workGroup}
              setSelectedWorkGroup={setSelectedWorkGroup}
            />
          </Col>
          <Col span={20}>
            <WgProjectData
              selectedWorkGroup={selectedWorkGroup}
              workGroup={workGroup}
              listUser={listUser}
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Layout>
      <HeaderLayout />
      <Layout>
        <NavBarLayout />
        <Layout style={{ padding: "0 24px 24px" }}>
          {isLoading ? (
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                backgroundColor: "transparent",
              }}
            >
              <Spin size="large" />
            <div className="ms-3 mt-2">読み込み中</div>{" "}
          </div>
          ) : (
            <div style={{ minHeight: 780 }}>
              <h1>プロジェクト管理</h1>
              <Tabs defaultActiveKey="tabWorkgroup" items={items} />
            </div>
          )}
          <FooterLayout />
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Projects;
