import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Table, Tag, Button } from 'antd';
import { UserContext } from './UserContext';
import { useCookies } from 'react-cookie';
import { Loading, getLocalToken, getLabelByValue, getLocalStorageInfo, sortArrayObject } from '../../utils/Common';
import '../../utils/PhoneKeyboard.css';


const statusList = [
  { label: 'Unknow', value: null },
  { label: '不在', value: '0' },
  { label: '興味なし', value: '1' },
  { label: '即断り', value: '3' },
  { label: '着信拒否', value: '4' },
  { label: '再アクション', value: '5' },
  { label: '面談アサイン', value: '6' },
  { label: 'イベントアサイン', value: '7' },
  { label: '電話番号不備', value: '8' },
  // { label: '接触前', value: '9' },
  { label: '追客中', value: '10' },
  { label: 'サービス停止', value: '11' },
  { label: 'サービス停止（メールOK）', value: '12' },
  { label: '前日確認OK', value: '13' },
  { label: 'キャンセル', value: '14' },
  { label: '日程変更', value: '15' },

];

const eventRow = {
  backgroundColor: '#d703fc'
};


function TwilioPhoneList({listUserPhone, selectedKey, setSelectedKey}) {
  const { setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const listData = useMemo(() => {
    if (listUserPhone.data && Object.keys(listUserPhone.data).length > 0) {
      return listUserPhone.data.map((item) => ({
        ID: item.candidates_id,
        Name: item.candidates_name,
        furigana: item.furigana,
        email: item.email,
        phone: item.candidates_phone,
        address: item.address,
        birthday: item.birthday,
        gender: item.gender,
        postal_code: item.postal_code,
        callee_number: item.callee_number,
        school_type: item.school_type,
        university_name: item.university_name,
        educational_stream: item.educational_stream,
        department: item.department,
        graduation_year: item.graduation_year,
        work_year: item.work_year,
        desired_industry: item.desired_industry,
        total_call: item.total_call,
        last_call: item.last_call,
        result_call: item.result,
        author_id: item.author_id,
        receipt_id: item.receipt_id,
        status: item.status,
      }));
    }
    return [];
  }, [listUserPhone]);

  const columnsConfig = useMemo(() => [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      sorter: (a, b) => a.ID - b.ID,
    },
    {
      title: ' 顧客名 ',
      dataIndex: 'Name',
      key: 'Name',
      render: (text, record) => <a onClick={() => setUser(record)}>{text}</a>,
    },
    {
      title: ' カウント',
      dataIndex: 'total_call',
      sorter: (a, b) => a.total_call - b.total_call,
      key: 'total_call',
    },
    {
      title: '最終架電日',
      dataIndex: 'last_call',
      key: 'last_call',
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
			render: (status) => (
				<Tag color='volcano'>
          {getLabelByValue(statusList, status)}
				</Tag>
			),
    },
    {
      title: '述べる',
      dataIndex: 'remark',
      key: 'remark',
    }
  ], [setUser]);

  useEffect(() => {
    if (listData.length > 0) {
      setUser(listData[selectedKey]);
    }
  }, [selectedKey, listData]);

  if (isLoading) {
    return <Loading />;
  }


  return (
    <div style={{ padding: '10px', height: '450px', backgroundColor: '#e5e5e5' }}>
        <Table
            columns={columnsConfig}
            rowKey={(record, index) => index}
            dataSource={listData}
            scroll={{ y: 300 }}
            pagination={{ position: ['bottomRight'] }}
            rowSelection={{
                type: "radio",
                selectedRowKeys: [selectedKey],
                onChange: (selectedRowKeys) => {
                    setSelectedKey(selectedRowKeys[0]);
                }
            }}
            rowClassName={(record) => ((record.total_call===1 ? 'custom-table-row' : ''))}
        />

    </div>
  );
}

export default TwilioPhoneList;