// src/PhoneKeyboard.js
import React, { useContext, useState } from "react";
import { message } from "antd";
import "../../utils/PhoneKeyboard.css";
import { UserContext } from "./UserContext";
import { postCallData } from "../../services/instanceCallService";

const PhoneKeyboard = () => {
  const [input, setInput] = useState("");
  const [isCallDisabled, setIsCallDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { author } = useContext(UserContext);

  const success = (contents) => {
    messageApi.open({
      type: "success",
      content: contents,
      className: "custom-class",
      style: { marginTop: "20vh" },
    });
  };

  const handleButtonClick = (value) => {
    setInput(input + value);
  };

  const handleBackspace = () => {
    setInput(input.slice(0, -1));
  };

  const handleClear = () => {
    setInput("");
    setIsCallDisabled(false);
  };

  const handleCall = async () => {
    const zoomPhone = "zoomphonecall://";
    if (input.length === 0) {
      success("電話番号を入力してください。ありがとうございます。");
      return false;
    }

    const params = {
      author_id: author.id,
    };

    // convert phone number like 09012345678 to 090-1234-5678
    const phoneNumber = input.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");

    await postCallData(`/call-log/${phoneNumber}/update`, params);

    const callUrl = `${zoomPhone}${phoneNumber}`;
    setIsCallDisabled(true);
    window.open(callUrl);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className="phone-keyboard">
      {contextHolder}
      <input
        type="text"
        className="display"
        value={input}
        onChange={handleInputChange}
        placeholder="電話番号を入力してください"
      />
      <div className="keypad">
        {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map(
          (value) => (
            <button key={value} onClick={() => handleButtonClick(value)}>
              {value}
            </button>
          )
        )}
        <button className="backspace" onClick={handleBackspace}>
          ⌫
        </button>
        <button className="clear" onClick={handleClear}>
          C
        </button>
        <button
          className="call"
          onClick={handleCall}
          disabled={isCallDisabled}
          style={{
            backgroundColor: isCallDisabled ? "#cccccc" : "#52c41a",
            color: isCallDisabled ? "#666666" : "#ffffff",
            cursor: isCallDisabled ? "not-allowed" : "pointer",
          }}
        >
          Call
        </button>
      </div>
    </div>
  );
};

export default PhoneKeyboard;
