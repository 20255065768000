import React, { useState, useEffect, useContext, useMemo } from 'react';
// import axios from 'axios';
import { Col, Row, Space, List } from 'antd';
// import globalConst from '../../constants/global_const';
// import { UserContext } from './UserContext';
// import { useCookies } from 'react-cookie';
import { getCurDate, getCurMonth } from '../../utils/Common';


function TwilioClientCount() {
    const [callTime, setCalltime] = useState(0);
    const [updateStatusTime, setUpdateStatustime] = useState(0);
  

    return(
        <>
        <Row>
            <div style={{ padding: '5px', height: '40px', width: '100%', backgroundColor: '#0592f5' }} >
            <div style={{display: 'table', margin: 'auto'}}><label style={{fontSize: '20px', color: '#fff'}}>
                <b>{getCurMonth()} / {getCurDate()} 月</b>
            </label></div>
            </div>
        </Row>

        <Row>
            <Col span={12}>
                <div style={{ padding: '5px', height: '70px', width: '100%', backgroundColor: '#ada2f2' }} >
                    <div style={{display: 'table', margin: 'auto'}}>
                        <label style={{fontSize: '20px', color: '#fff'}}><b>{updateStatusTime}</b></label>
                    </div>
                    <div style={{display: 'table', margin: 'auto'}}>
                        <label style={{fontSize: '15px', color: '#fff'}}>コール</label>
                    </div>
                    
                </div>
            </Col>
            <Col span={12}>
                <div style={{ padding: '5px', height: '70px', width: '100%', backgroundColor: '#9d8ff2' }} >
                    <div style={{display: 'table', margin: 'auto'}}>
                        <label style={{fontSize: '20px', color: '#fff'}}><b>{callTime}</b></label>
                    </div>
                    <div style={{display: 'table', margin: 'auto'}}>
                        <label style={{fontSize: '15px', color: '#fff'}}>アポ</label>
                    </div>
                </div>
            </Col>
        </Row>

        </>
    )

}

export default TwilioClientCount;