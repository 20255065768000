import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import HomeAdmin from "./components/home/HomeAdmin";
import Login from "./components/login/Login";
import UserManager from "./components/users/UserManager";
import CallMeeting from "./components/call/CallMeeting";
import WorkGroups from "./components/projects/WorkGroups";
import Projects from "./components/projects/Projects";
import GSSList from "./components/reports/GSS_list";
import GSSSync from "./components/reports/GSS_Sync";
import CallLogs from "./components/call/CallLogs";
import CallRecording from "./components/call/CallRecoding";
import UserGroup from "./components/users/UserGroup";
import CandidateList from "./components/call/CandidateList";
import CallTwilio from "./components/call/CallTwilio";
import CallReportZoom from "./components/reports/CallReportZoom";

import ProtectedRoutes from "./components/ProtectedRoutes";
import PublicRoutes from "./components/PublicRoute";
import AutomaticDistribution from "./components/call/AutomaticDistribution";
import Password from "antd/es/input/Password";
import PasswordReset from "./components/users/PasswordReset";

const MainRoutes = () => (
  <Routes>
    {/** Protected Routes */}
    {/** Wrap all Route under ProtectedRoutes element */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route
        path="/"
        element={<Navigate replace to="homeAdmin/call-meeting" />}
      />
      {/* <Route path="homeAdmin/*" element={<HomeAdmin />} /> */}
      <Route path="homeAdmin/user-manager" element={<UserManager />} />
      <Route path="homeAdmin/user-group" element={<UserGroup />} />
      <Route path="homeAdmin/call-meeting" element={<CallMeeting />} />
      <Route path="homeAdmin/call-twilio" element={<CallTwilio />} />
      <Route path="homeAdmin/candidate-list" element={<CandidateList />} />
      <Route path="homeAdmin/call-logs" element={<CallLogs />} />
      <Route path="homeAdmin/call-recording" element={<CallRecording />} />
      <Route path="homeAdmin/work-groups" element={<WorkGroups />} />
      <Route path="homeAdmin/project-list" element={<Projects />} />
      <Route
        path="homeAdmin/automatic-distribution"
        element={<AutomaticDistribution />}
      />

      <Route path="homeAdmin/call-report-zoom" element={<CallReportZoom />} />
      <Route path="homeAdmin/gss-list" element={<GSSList />} />
      <Route path="homeAdmin/gss-sync" element={<GSSSync />} />
      <Route path="homeAdmin/password-reset" element={<PasswordReset />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Route>

    {/** Public Routes */}
    {/** Wrap all Route under PublicRoutes element */}
    <Route path="login" element={<PublicRoutes />}>
      <Route path="/login" element={<Login />} />
    </Route>
  </Routes>
);

export default MainRoutes;
