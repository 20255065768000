import global_const from "../constants/global_const";
import axios from "axios";
import { getCookies } from "../utils/getCookies";
import { logoutUser, passwordReset } from '../utils/Common';

const instanceUserService = axios.create({
  baseURL: global_const.API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

instanceUserService.interceptors.request.use(
  (config) => {
    const curToken = getCookies();
    config.headers.Authorization = `Bearer ${curToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
instanceUserService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized request - logging out");
      logoutUser(); // Call your logout function if a 401 error occurs
    }
    if (error.response && error.response.status === 419) {
      console.error("Authorized expired - redirect to password reset");
      passwordReset(); // Call your logout function if a 401 error occurs
    }
    return Promise.reject(error);
  }
);


export const getDataUser = async (endpoint) => {
  try {
    const response = await instanceUserService.get(endpoint);
    return response;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const postDataUser = async (endpoint, data) => {
  try {
    const response = await instanceUserService.post(endpoint, data);
    return response;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

export const putDataUser = async (endpoint, data) => {
  try {
    const response = await instanceUserService.put(endpoint, data);
    return response;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

export const deleteDataUser = async (endpoint) => {
  try {
    const response = await instanceUserService.delete(endpoint);
    return response;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};
