import { Button, Flex, Modal, Table } from "antd";
import React, { useState } from "react";
import { getCallData, postCallData } from "../../services/instanceCallService";
import {
  setLocalStorageInfo,
} from "../../utils/Common";

const columns = [
  {
    title: "ステータス",
    dataIndex: "status",
  },
];

const dataSource = [
  // { status: "なし", key: "-1" },
  { status: "不在", key: "0" },
  // { status: "興味なし", key: "1" },
  // { status: "即断り", key: "3" },
  // { status: "着信拒否", key: "4" },
  { status: "再アクション", key: "5" },
  // { status: "面談アサイン", key: "6" },
  // { status: "イベントアサイン", key: "7" },
  // { status: "電話番号不備", key: "8" },
  // { status: "接触前", key: "9" },
  // { status: "追客中", key: "10" },
  // { status: "サービス停止", key: "11" },
  // { status: "サービス停止（メールOK）", key: "12" },
  // { status: "前日確認OK", key: "13" },
  // { status: "キャンセル", key: "14" },
  // { status: "日程変更", key: "15" },
];

const TypeModal = ({
  title,
  isModalType,
  handleCloseTypeModal,
  setIsSubmit,
  messageApi,
  project_id,
  setListUser,
  socket
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
 
  // Emit event when User A clicks "Push"
  function updatePollingStatus(status) {
    setLocalStorageInfo("pickSuccess", status);
    //socket.emit("updatePollingStatus", status);
    updateStatus(status);
  }

  
  // Function to update the status via POST to /events/status
  const updateStatus = async (status) => {
    const updateParam = {
      status: status,
    };

    // Update the status
    try {
      const response = await postCallData(`/events/status`, updateParam);
      console.log("Update status:", response.data); // Handle the response from the server
    } catch (error) {
      console.log(error);
    } finally {

    }

  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    try {
      if (selectedRowKeys.includes(" ")) {
        const arrayProject = [project_id];
        const result = await getCallData(
          `/call-log/pick?project_id=${arrayProject}`
        );
        if (result.data.length === 0) {
          messageApi.error("データがありません");
        } else {
          setListUser((prev) => [result.data, ...prev]);
          messageApi.success("データが取得されました");
        }
      } else {
        const selectedRowKeysInt = selectedRowKeys.map((key) => parseInt(key));
        // Check if selectedRowKeysInt is empty or null
        if (!selectedRowKeysInt || selectedRowKeysInt.length === 0) {
          selectedRowKeysInt = [0, 5];
        }
        const result = await getCallData(
          `/call-log/pick?status=${selectedRowKeysInt}&project_id=[${project_id}]`
        );
        if (result.data.length === 0) {
          messageApi.error("データがありません");
          sessionStorage.setItem("polling", false);
          //updatePollingStatus(false);
        } else {
          setListUser((prev) => [result.data, ...prev]);
          messageApi.success("データが取得されました");
          sessionStorage.setItem("polling", true);
          //updatePollingStatus(true);
        }
      }
    } catch (error) {
      console.log(error);
      handleCloseTypeModal();
    } finally {
      handleCloseTypeModal();
    }
  };

  return (
    <Modal
      title={title}
      open={isModalType}
      onOk={handleCloseTypeModal}
      onCancel={handleCloseTypeModal}
      footer={
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      }
    >
      <Flex gap="middle" vertical>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
        />
      </Flex>
    </Modal>
  );
};

export default TypeModal;
