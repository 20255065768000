module.exports = Object.freeze({
  API_URL:
    process.env.NODE_ENV === "production"
      ? "/api"
      : "https://localhost/api",
  INTERNAL_URL:
    process.env.NODE_ENV === "production" ? "" : "https://localhost",
  MANAGEMENT_URL:
    process.env.NODE_ENV === "production"
      ? "/api"
      : "https://localhost/api",
  REPORT_URL:
    process.env.NODE_ENV === "production"
      ? "/api"
      : "https://localhost/api",
  CALL_SERVICE_URL:
    process.env.NODE_ENV === "production"
      ? "/api"
      : "https://localhost/api",
  TWILIO_SERVEICE_URL:
    process.env.NODE_ENV === "production"
      ? "https://8511-113-161-82-162.ngrok-free.app"
      : "https://8511-113-161-82-162.ngrok-free.app",
});
