import React, { useContext } from 'react';
import { Descriptions } from 'antd';
import { UserContext } from './UserContext';
import { PhoneTwoTone } from '@ant-design/icons';

export default function PhoneHeader() {
  const { userInfo } = useContext(UserContext);

  let items = [];

  if (userInfo) {
    const entries = Object.entries(userInfo);
    const zoomPhone = 'zoomphonecall://';
    for (let i = 0; i < entries.length && i < 6; i++) {
      const [key, value] = entries[i];
     
      items.push({
        key,
        label: <b style={{color: 'hotpink'}}>{
          key === 'ID' ? 'NO.' :
          key === 'Name' ? '名前' : 
          key === 'furigana' ? 'フリガナ' : 
          key === 'email' ? 'メールアドレス' : 
          key === 'phone' ? 'Tel' : 
          key === 'address' ? '住所' : ''

        }</b>,
        children: (key === 'phone') 
        ? 
        <a href={`${zoomPhone}${value}`}>{value}  <PhoneTwoTone style={{ fontSize: '20px'}} twoToneColor="#52c41a"/></a> 
        : 
        <label style={{color: '#55f'}}><b>{value}</b></label>,
      });
    }
  }

  return (
    <div style={{ padding: '10px', height: '140px', backgroundColor: '#fff' }}>
      <Descriptions title="ユーザー詳細" items={items} />
    </div>
  );
}
