import React, { useEffect, useState } from 'react';
import { Layout, Modal, Form, Button, Input, message } from 'antd';

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { logoutUser, getLocalToken } from '../../utils/Common';

const globalConst = require('../../constants/global_const');
const { Content } = Layout;

function PasswordReset() {
    const [messageApi, contextHolder] = message.useMessage();

    const [cwForm] = Form.useForm();
    const [open, setOpen] = useState(false);

    const [cookies] = useCookies(['call_token']);
    const curToken = cookies.call_token ? cookies.call_token : getLocalToken();

    const [isWaiting, setIsWaiting] = useState(false);

    const showCWModal = () => {
        setOpen(true);
    };
    
    const handleCWFormSubmit = async (values) => {
        setIsWaiting(true);
        changeUserPW(values);
    };
    
    const successMessage = (content) => {
        messageApi.open({
            type: 'success',
            content,
            className: 'custom-class',
            style: { marginTop: '20vh' },
        });
    };
    
    const changeUserPW = async (params) => {

        // console.log('data to change ', params);
        try {
            const result = await axios.post(
                `${globalConst.API_URL}/auth/change-password`,
                { ...params },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${curToken}`,
                    },
                },
            );
    
            successMessage(`Password had change successfull !!!`);
            setOpen(false);
            logoutUser();
        } catch (error) {
            console.error('Error fetching user data:', error);
            if (error.response?.data?.error && error.response.data.error === "新しいパスワードは、以前のパスワードと同じにすることはできません。") {
                messageApi.error(error.response.data.error);
            }
            setIsWaiting(false);
        }
    }

    useEffect(() => {
        showCWModal();
    }, []);

    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpen(true);
    };

    return (
        <Layout>
            <HeaderLayout />
            <Layout>
                <NavBarLayout />
                <Content style={{ padding: '0 24px 24px', height: '900px' }}>
                    <div style={{ minHeight: 780 }}>
                        {contextHolder}
                        <Modal
                            title="Password has expired. Please reset your password."
                            open={open}
                            confirmLoading={false}
                            onCancel={handleCancel}
                            footer={null}
                            maskClosable={false}
                        >
                            <Form
                                {...{
                                    labelCol: { span: 8 },
                                    wrapperCol: { span: 16 },
                                }}
                                form={cwForm}
                                onFinish={handleCWFormSubmit}
                            >
                                <Form.Item
                                    name="password"
                                    label="新しいパスワード"
                                    rules={[{ required: true }]}
                                >
                                    <Input.Password
                                        placeholder="新しいパスワード"
                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password_confirmation"
                                    label="パスワードの確認"
                                    rules={[{ required: true }]}
                                >
                                    <Input.Password
                                        placeholder="パスワードの確認"
                                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit" loading={isWaiting}>提出する</Button>&nbsp;&nbsp;
                                    <Button htmlType="button" onClick={handleCancel}>閉じる</Button>
                                </Form.Item>
                            </Form>
                        </Modal>     
                    </div>
                    <FooterLayout />
                </Content>
            </Layout>
        </Layout>                    
    );
}

export default PasswordReset;