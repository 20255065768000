import React from "react";
//import {Link} from "react-router-dom";
import { Layout} from 'antd';
//import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

//const { SubMenu } = Menu;
const { Footer } = Layout;

const FooterLayout = () =>{
    return (
        <Footer style={{ textAlign: 'center' }}>DYMVietNam©2024 Created by DYMVietNam</Footer>
    );
}

export default FooterLayout;