import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button, Col, Row, Space, message } from 'antd';
import globalConst from '../../constants/global_const';
import { UserContext } from './UserContext';
import { getCurDate, getCurMonth, getLocalStorageInfo, setLocalStorageInfo, getLocalToken } from '../../utils/Common';
import { 
    PhoneTwoTone, RightSquareTwoTone, LeftSquareTwoTone, TrademarkOutlined, 
    AudioMutedOutlined, StopOutlined, AudioOutlined 
} from '@ant-design/icons';
import '../../utils/PhoneCall.css';
import { Device } from '@twilio/voice-sdk';
import { useCookies } from "react-cookie";

function TwilioPhoneCall({ handleNext, handlePrevious }) {
    const { userInfo } = useContext(UserContext);
    const [dataCount, setDataCount] = useState(['Not yet select']);
    const [messageApi, contextHolder] = message.useMessage();
    const userLocal = getLocalStorageInfo('curUser');
    const author_id = userLocal?.id;
    const [deviceStatus, setDeviceStatus] = useState('');
    const [callStatus, setCallStatus] = useState('Not set');
    const [callToken, setCallToken] = useState('');
    const [incomingCall, setIncomingCall] = useState(null);
    const [device, setDevice] = useState(null);
    const [call, setCall] = useState(null);
    const [callControlsVisible, setCallControlsVisible] = useState(false);
    const [volumeIndicatorsVisible, setVolumeIndicatorsVisible] = useState(false);
    const [incomingPhoneNumber, setIncomingPhoneNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isMuted, setIsMuted] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordingSid, setRecordingSid] = useState(null);
    const [micStatus, setMicStatus] = useState(false);
    const [cookies] = useCookies(["call_token"]);
    const curToken = cookies.call_token ? cookies.call_token : getLocalToken();
  

    const clientName = 'DYMVietNam';

    const success = (contents) => {
        messageApi.open({
            type: 'success',
            content: contents,
            className: 'custom-class',
            style: { marginTop: '20vh' },
        });
    };

    useEffect(() => {
        const fetchStatusCount = async () => {
            const result = await axios.get(`${globalConst.CALL_SERVICE_URL}/call-log/count?user_id=${author_id}`, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },
            });
            setDataCount(result.data.data);
        };
        fetchStatusCount();
        checkMicrophone();
    }, [author_id]);

    const handleIncomingCall = (connection) => {
        setIncomingPhoneNumber(connection.parameters.From);
        setIncomingCall(connection);
        setCallStatus('Incoming call');
        log(`Incoming connection from ${connection.parameters.From}`);
    };

    const log = (message) => {
        console.log(message);
        messageApi.open({
            type: 'info',
            content: message,
            className: 'custom-class',
            style: { marginTop: '20vh' },
        });
    };

    const initializeDevice = async () => {
        const selectedPhoneName = getLocalStorageInfo('phone-name');
        const selectedPhoneFrom = getLocalStorageInfo('phone-from');
        const selectedPhoneTo = getLocalStorageInfo('phone-to');
        const selectedReceipt = getLocalStorageInfo('receipt-id');
        const userInfo = getLocalStorageInfo('curUser');
        const twilioInfo = getLocalStorageInfo('twilio-info');

        /**
         *  For test call with param is constant
         *
        */
        let callParam = {
            "From": '+17078760766',
            "To": '+84966150638',
            "identity": clientName
        }

        if(selectedPhoneName?.length > 0) {
            callParam = {
                "From": selectedPhoneFrom,
                "To": selectedPhoneTo,
                "identity": selectedPhoneName
            }

        }

        console.log('Twilio param info ', twilioInfo, callParam);
        //return false;

        try {
            const response = await axios.post(globalConst.TWILIO_SERVEICE_URL + '/start-session', callParam, {
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${curToken}`,
                },
            });
            console.log('token return ', response.data);
            const { token } = response.data;
            const newDevice = new Device(token, {
                logLevel: 1,
                codecPreferences: ["opus", "pcmu"],
            });

            addDeviceListeners(newDevice);
            newDevice.register();
            setDevice(newDevice);
            setLocalStorageInfo('twilio-info', JSON.stringify(response.data));
            log('Initializing device');
        } catch (error) {
            setDeviceStatus(`Error creating device: ${error.message}`);
        }


    };

    const addDeviceListeners = (device) => {
        device.on('registered', () => {
            log('Twilio.Device Ready to make and receive calls!');
            setCallControlsVisible(true);
        });

        device.on('error', (error) => {
            log(`Twilio.Device Error: ${error.message}`);
        });

        device.on('incoming', handleIncomingCall);

        device.audio.on('deviceChange', updateAllAudioDevices);

        if (device.audio.isOutputSelectionSupported) {
            log('Audio selection is supported.');
        }
    };

    const updateAllAudioDevices = () => {
        log('Audio device change detected.');
    };

    const makeOutgoingCall = async () => {
        if (device) {
            log(`Attempting to call ${phoneNumber} ...`);
            const call = await device.connect({ params: { To: phoneNumber } });

            call.on('accept', () => updateUIAcceptedOutgoingCall(call));
            call.on('disconnect', updateUIDisconnectedOutgoingCall);
            call.on('cancel', updateUIDisconnectedOutgoingCall);

            setCall(call);
        } else {
            log('Unable to make call.');
        }
    };

    const updateUIAcceptedOutgoingCall = (call) => {
        log('Call in progress ...');
        setVolumeIndicatorsVisible(true);
    };

    const updateUIDisconnectedOutgoingCall = () => {
        log('Call disconnected.');
        setVolumeIndicatorsVisible(false);
    };

    const acceptIncomingCall = async() => {
        const selectedReceipt = getLocalStorageInfo('receipt-id');

        if (incomingCall) {
            incomingCall.accept();
            setCall(incomingCall);
            log('Accepted incoming call.');
        }

        /**
         * Write to logs
         */
        /*
        const updateParam = {
            "author_id": author_id,
            "receipt_id": selectedReceipt,
            "status": 'accepted',
        };

        const results = await axios.post(globalConst.CALL_SERVICE_URL + '/call-log/status', updateParam, {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
            },
        });
        if (results.data.success) {
            success('Update status success' );
            //window.location.reload();
        } else {
            success('Update status had failed ' );
        }
        */
    };

    const rejectIncomingCall = () => {
        const selectedReceipt = getLocalStorageInfo('receipt-id');

        if (incomingCall) {
            incomingCall.reject();
            log('Rejected incoming call.');
            resetIncomingCallUI();
        }

        /*
        const updateParam = {
            "author_id": author_id,
            "receipt_id": selectedReceipt,
            "status": 'rejected',
        };

        const results = await axios.post(globalConst.CALL_SERVICE_URL + '/call-log/status', updateParam, {
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
            },
        });
        if (results.data.success) {
            success('Update status success' );
            //window.location.reload();
        } else {
            success('Update status had failed ' );
        }
        */
    };

    const hangupCall = () => {
        if (call) {
            log('Attempting to hang up call.');
            call.disconnect();  // Ngắt kết nối cuộc gọi
            log('Call disconnected.');
            resetIncomingCallUI();  // Đặt lại giao diện người dùng cuộc gọi đến
            setCall(null);  // Đặt lại trạng thái cuộc gọi
        } else {
            log('No call to hang up.');
        }
    };

    const resetIncomingCallUI = () => {
        setIncomingCall(null);
        setIncomingPhoneNumber('');
    };

    const toggleMute = () => {
        if (call) {
            call.mute(!isMuted);
            setIsMuted(!isMuted);
            log(isMuted ? 'Unmuted the call.' : 'Muted the call.');
        }
    };

    const startRecording = async () => {
        if (call && !isRecording) {
            try {
                const response = await axios.post(`${globalConst.TWILIO_SERVEICE_URL}/start-recording`, {
                    callSid: call.parameters.CallSid
                });
                setRecordingSid(response.data.sid);
                setIsRecording(true);
                log('Recording started.');
            } catch (error) {
                log(`Error starting recording: ${error.message}`);
            }
        }
    };

    const stopRecording = async () => {
        if (isRecording && recordingSid) {
            try {
                await axios.post(`${globalConst.TWILIO_SERVEICE_URL}/stop-recording`, {
                    recordingSid
                });
                setRecordingSid(null);
                setIsRecording(false);
                log('Recording stopped.');
            } catch (error) {
                log(`Error stopping recording: ${error.message}`);
            }
        }
    };
/*
    const handleClientNameChange = (e) => {
        setClientName(e.target.value);
    };
*/
    const checkMicrophone = async () => {
        const permission = await navigator.permissions.query( { name: "microphone" } );
        //console.log('Check mic ', permission, micStatus);
        if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
          try {
              const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
              setMicStatus(true);
              stream.getTracks().forEach(track => track.stop()); // Dừng stream sau khi kiểm tra
          } catch (err) {
            setMicStatus(false);
            console.log('Microphone access denied: ' + err.message);
          }
        } else {
            setMicStatus(false);
            console.log('getUserMedia not supported on your browser!');
        }
    
    
    };
    

    return (
        <>
            {contextHolder}
            <Row>
                <div>
                {incomingPhoneNumber && (
                    <>
                    <div>Incoming call from {incomingPhoneNumber}</div>
                    <Button type="primary" onClick={acceptIncomingCall}>
                        Accept
                    </Button>
                    <Button type="danger" onClick={rejectIncomingCall}>
                        Reject
                    </Button>
                    <Button type="danger" onClick={hangupCall}>
                        Hang Up
                    </Button>
                    </>
                )}
                </div>
                {volumeIndicatorsVisible && <div>Volume Indicators</div>}
            </Row>

            <Row className="phone-call-container">
                <Col xs={24} md={10} align="middle">
                    <Row className="icon-container">
                        <Col span={24}>
                            <Space>
                                {
                                    callControlsVisible ? (
                                    <>
                                        <input
                                            type="text"
                                            placeholder="電話番号を入力してください"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                        <a href='#' onClick={makeOutgoingCall}>
                                            <div >
                                                <PhoneTwoTone style={{ fontSize: '25px' }} twoToneColor="#52c41a" />
                                            </div>
                                        </a>
                                    </>
                                    )
                                    : micStatus ? (
                                        <>
                                            <Button type="dashed" danger onClick={initializeDevice} icon={<PhoneTwoTone />}>
                                                Initialize Device
                                            </Button>
                                        </>
                                        
                                    ) : (
                                        <><label style={{color: '#f55'}}><b>Microphone not allow, please check microphone </b></label></>
                                    )
                                
                                }
                                
                            </Space>
                        </Col>
                    </Row>

                    <Row>
                        {
                            callControlsVisible ? 
                            <>
                            <Col xs={8}>
                                <a href='#' onClick={toggleMute}>
                                    {
                                        isMuted ? 
                                        <AudioMutedOutlined style={{
                                            fontSize: '25px',
                                            color: 'hotpink',
                                        }} /> : 
                                        <AudioOutlined style={{
                                            fontSize: '25px',
                                            color: 'hotpink',
                                        }}/>
                                    }
                                </a>
                            </Col>

                            <Col xs={8}>
                                <a type="default" onClick={isRecording ? stopRecording : startRecording}>
                                    {
                                        isRecording ? 
                                        <StopOutlined style={{ fontSize: '25px', color: 'hotpink', }}/> : 
                                        <TrademarkOutlined style={{ fontSize: '25px', color: 'hotpink', }}/>
                                    }
                                </a>
                            </Col>

                            <Col xs={8}>
                                <a href='#' onClick={hangupCall}>
                                <StopOutlined style={{
                                    fontSize: '25px',
                                    color: 'hotpink',
                                }} />

                                </a>
                            </Col>

                            </> :
                            <><label style={{color: '#52c41a'}}><b>Click on Initialize Device for start call</b></label></>
                        }
                    </Row>

                    <Row style={{ marginTop: '10px' }}>
                        <Col xs={12}>
                            <Button type="link" onClick={handlePrevious}>
                                <LeftSquareTwoTone style={{
                                    fontSize: '30px',
                                }} />
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Button type="link" onClick={handleNext}>
                                <RightSquareTwoTone style={{
                                    fontSize: '30px',
                                }} />
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={14}>
                    <Row className="date-container">
                        <div className="date-display">
                            <label className="date-label">
                                <b>{getCurMonth()} / {getCurDate()} 月</b>
                            </label>
                        </div>
                    </Row>

                    <Row>
                        <Col xs={24} md={12}>
                            <div className="stat-container" style={{ backgroundColor: '#ada2f2' }}>
                                <div className="stat-content">
                                    <label className="stat-number"><b>{dataCount?.callPerDay}</b></label>
                                    <label className="stat-label">コール</label>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <div className="stat-container" style={{ backgroundColor: '#9d8ff2' }}>
                                <div className="stat-content">
                                    <label className="stat-number"><b>{dataCount?.callPerStatus}</b></label>
                                    <label className="stat-label">アポ</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </>
    );
}

export default TwilioPhoneCall;
