import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Row, Space, message } from "antd";
import { UserContext } from "./UserContext";
import {
  getCurDate,
  getCurMonth,
  getLocalStorageInfo,
  setLocalStorageInfo,
} from "../../utils/Common";
import {
  PhoneTwoTone,
  RightSquareTwoTone,
  LeftSquareTwoTone,
} from "@ant-design/icons";

import "../../utils/PhoneCall.css";
import { postCallData } from "../../services/instanceCallService";

function PhoneCall({ handleNext, handlePrevious, dataCount }) {
  const { userInfo, authorId } = useContext(UserContext);
  const [messageApi, contextHolder] = message.useMessage();

  const success = (contents) => {
    messageApi.open({
      type: "success",
      content: contents,
      className: "custom-class",
      style: { marginTop: "20vh" },
    });
  };

  const zoomPhone = "zoomphonecall://";
  const userPhone = userInfo?.phone;

  const setCallCount = async () => {
    const selectedStatus = getLocalStorageInfo("selected-status");
    const userInfo = getLocalStorageInfo("curUser");
    const callUrl = `${zoomPhone}${userPhone}`;
    window.open(callUrl);

    const author_id = userInfo?.id;
    const updateParam = {
      author_id: author_id,
      status: selectedStatus,
    };

    const results = await postCallData("/call-log/status", updateParam);

    if (results.data.success) {
      success("Update status success");
    } else {
      success("Update status had failed ");
    }
  };

  return (
    <>
      {contextHolder}
      <Row className="phone-call-container">
        <Col xs={24} md={10} lg={8} align="middle">
          <Row className="icon-container">
            <Col span={24}>
              <Space>
                <a href="#" onClick={setCallCount}>
                  <div className="call-icon">
                    <PhoneTwoTone
                      style={{ fontSize: "55px" }}
                      twoToneColor="#52c41a"
                    />
                  </div>
                </a>
              </Space>
            </Col>
            <div className="w-full flex justify-content-center">
              <Button type="link" onClick={handlePrevious}>
                <LeftSquareTwoTone
                  style={{
                    fontSize: "30px",
                  }}
                />
              </Button>
              <Button type="link" onClick={handleNext}>
                <RightSquareTwoTone
                  style={{
                    fontSize: "30px",
                  }}
                />
              </Button>
            </div>
          </Row>
        </Col>

        <Col xs={24} md={14} lg={16}>
          <Row className="date-container">
            <div className="date-display">
              <label className="date-label">
                <b>
                  {getCurMonth()}月{getCurDate()}日
                </b>
              </label>
            </div>
          </Row>

          <Row>
            <Col xs={24} md={12}>
              <div
                className="stat-container"
                style={{ backgroundColor: "#ada2f2" }}
              >
                <div className="stat-content">
                  <label className="stat-number">
                    <b>{dataCount?.hitPerDay}</b>
                  </label>
                  <label className="stat-label">コール</label>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div
                className="stat-container"
                style={{ backgroundColor: "#9d8ff2" }}
              >
                <div className="stat-content">
                  <label className="stat-number">
                    <b>{dataCount?.callPerStatus}</b>
                  </label>
                  <label className="stat-label">アポ</label>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default PhoneCall;
